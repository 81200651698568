import React, {
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
    Dispatch,
    SetStateAction
} from "react";
import { useTranslation } from "react-i18next";

interface PaginationProps {
    totalItems: number;
    itemsPerPage?: number;
    onPageChange: (page: number) => void;
    ref: any;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
}

export const countTotalPage = (totalItems: number, itemsPerPage: number) => {
    return Math.ceil(totalItems / itemsPerPage);
};

const ComponentPagination: React.FC<PaginationProps> = forwardRef(
    ({ totalItems = 0, itemsPerPage = 10, onPageChange, currentPage, setCurrentPage }, ref) => {
        const { t } = useTranslation();

        const [totalPages, setTotalPages] = useState<number>(
            countTotalPage(totalItems, itemsPerPage)
        );

        const handleClickPage = (page: number) => {
            if (currentPage === page) {
                return;
            }
            setCurrentPage(page);
            onPageChange(page); // Notify the parent component about the page change
        };

        const handleClickPrevious = () => {
            if (currentPage === 0) {
                return;
            }
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
            onPageChange(currentPage - 1); // Notify the parent component about the page change
        };

        const handleClickNext = () => {
            if (currentPage + 1 === totalPages) {
                return;
            }
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
            onPageChange(currentPage + 1); // Notify the parent component about the page change
        };

        // Expose methods to parent
        useImperativeHandle(
            ref,
            () => {
                return {
                    resetPagination() {
                        setCurrentPage(0);
                    }
                };
            },
            []
        );

        useEffect(() => {
            setTotalPages(countTotalPage(totalItems, itemsPerPage));
        }, [totalItems]);

        // Helper function to generate array of page numbers for rendering
        const generatePageNumbers = (): number[] => {
            const maxButtonsToShow = 5; // Adjust this number based on how many buttons you want to show
            const buttons: number[] = [];
            let startPage = 0;
            let totalPagesToShow = totalPages;

            if (totalPages > maxButtonsToShow) {
                totalPagesToShow = Math.min(totalPages, maxButtonsToShow);
                if (currentPage >= Math.floor(maxButtonsToShow / 2)) {
                    startPage = Math.min(
                        totalPages - maxButtonsToShow,
                        currentPage - Math.floor(maxButtonsToShow / 2)
                    );
                }
            }

            for (let i = startPage; i < startPage + totalPagesToShow; i++) {
                buttons.push(i);
            }

            if (
                totalPages > maxButtonsToShow &&
                currentPage + Math.floor(maxButtonsToShow / 2) < totalPages - 1
            ) {
                buttons.push(-1); // Add ellipsis
                buttons.push(totalPages - 1); // Add last page
            }

            return buttons;
        };

        return (
            <div className="text-center">
                <div className=" inline-flex items-start sm:items-center gap-2.5 justify-center">
                    <button
                        className={`text-center text-lg font-semibold w-[80px] ${
                            currentPage === 0
                                ? "text-stone-300"
                                : "text-secondary"
                        }`}
                        onClick={handleClickPrevious}
                        disabled={totalPages === 0 || currentPage === 0}
                    >
                        {t("previous")}
                    </button>
                    <div className="flex flex-1 flex-wrap justify-center items-center gap-2.5 md:mx-7">
                        {generatePageNumbers().map((pageIndex) => {
                            if (pageIndex === -1) {
                                return (
                                    <span
                                        key="ellipsis"
                                        className="border-secondary border flex items-center justify-center leading-none flex-col rounded-[50px] w-10 h-10 xmy-4 text-secondary text-center relative"
                                    >
                                        ...
                                    </span>
                                );
                            }

                            return (
                                <button
                                    key={pageIndex}
                                    className={`border-secondary border  rounded-[50px] w-10 h-10 xmy-4 hover:bg-secondary hover:text-white transition-all duration-200 ${
                                        totalPages === 0 ||
                                        currentPage === pageIndex
                                            ? "bg-secondary text-white"
                                            : "text-secondary"
                                    }`}
                                    onClick={() => handleClickPage(pageIndex)}
                                >
                                    {pageIndex + 1}
                                </button>
                            );
                        })}
                    </div>
                    <button
                        className={`text-center text-lg font-semibold w-[80px] ${
                            totalPages === 0 || currentPage + 1 === totalPages
                                ? "text-stone-300"
                                : "text-secondary"
                        }`}
                        onClick={handleClickNext}
                        disabled={
                            totalPages === 0 || currentPage + 1 === totalPages
                        }
                    >
                        {t("next")}
                    </button>
                </div>
            </div>
        );
    }
);

export default ComponentPagination;
