// @ts-nocheck

import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClubProfileAbout from "./ClubProfileAbout";
import ClubProfileMap from "./ClubProfileMap";
import ClubProfileMembership from "./ClubProfileMembership";
import ClubProfileBookCentre from "./ClubProfileBookCentre";
import ClubProfileActivities from "./ClubProfileActivities";
import { updateClubInfo } from "../../Api/api";
import { CgSpinner } from "react-icons/cg";
// import TrainingComponent from "./TrainingComponent";
// import TournamentComponent from "./TournamentComponent";
// import MatchComponent from "./MatchComponent";

import ProfileCredits from "./ProfileCredits";
import { AuthContext } from "../AuthProvider";
import { useTranslation } from "react-i18next";

interface ClubProfileContentProps {
    clubData: any;
    userCredit: number;
    setIsSlot?: Dispatch<SetStateAction<boolean>>;
}

const ClubProfileContent: React.FC<ClubProfileContentProps> = ({
    clubData,
    userCredit,
    setIsSlot,
    selectedActivity,
    setSelectedActivity
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentUser = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [following, setFollowing] = useState(false);

    const handleRedirectLogin = () => {
        const queryParams = new URLSearchParams();
        queryParams.set("page", `/club/${clubData?._id}`);
        queryParams.set("action", "membership");
        const existingParams = new URLSearchParams(window.location.search);
        existingParams.forEach((value, key) => {
            queryParams.set(key, value);
        });
        const queryString = queryParams.toString();
        const url = `/sign-in?${queryString}`;

        navigate(url);
        return false;
    };

    useEffect(() => {
        if (clubData) {
            setFollowing(clubData?.following);
        }
    }, [clubData]);

    const handleFollowClub = () => {
        const clubId = clubData?._id;
        if (!currentUser) {
            const queryParams = new URLSearchParams();
            queryParams.set("page", `/club/${clubId}`);
            queryParams.set("action", "follow");
            const existingParams = new URLSearchParams(window.location.search);
            existingParams.forEach((value, key) => {
                queryParams.set(key, value);
            });
            const queryString = queryParams.toString();
            const url = `/sign-in?${queryString}`;

            navigate(url);
            return false;
        }
        setLoading(clubId);
        const body = {
            following: following ? false : true,
            notification: {
                match_post: true,
                tournament_post: true,
                training_post: true
            }
        };
        updateClubInfo(clubId, currentUser?.uid, body).then(
            (followClubResp: any) => {
                if (followClubResp?.data.success) {
                    if (following) setFollowing(false);
                    else setFollowing(true);
                    setLoading(false);
                }
            }
        );
    };

    return (
        <div className="px-4 xl:px-16 mx-auto flex w-full max-w-[1412px] justify-center items-center">
            <div className="w-full max-w-[1412px] mx-auto flex flex-col md:flex-row md:flex-wrap gap-4 xl:gap-10 mt-5 md:mt-10">
                <div className="flex flex-col xl:flex-row gap-4 md:gap-0 xl:max-w-[425px] w-full md:w-[calc(40%-8px)] xl:w-[calc(50%-20px)] ml-auto">
                    <div className="hidden md:mt-5 md:flex flex-col gap-4 md:px-4">
                        <div className="w-[95px] h-[95px] overflow-hidden border border-[#000b29]/40 rounded-[6px]">
                            <img
                                src={clubData?.photo_url}
                                className="w-[94px] h-[94px] object-cover rounded-[6px]"
                                alt="Club Profile"
                            />
                        </div>
                        <button
                            className={`text-sm flex justify-center items-center rounded-full px-1 border border-[#00081e] w-full max-w-[95px] min-h-[27px] font-medium hover:bg-primary hover:text-secondary transform duration-200
            ${
                following
                    ? "text-[#00081e] bg-white"
                    : "text-white bg-[#00081e]"
            }`}
                            onClick={() => handleFollowClub()}
                        >
                            {following ? (
                                <span className="flex justify-center items-center gap-1">
                                    {t("Profile.following")}
                                    {loading ? (
                                        <CgSpinner
                                            size="14px"
                                            className="animate-spin"
                                        />
                                    ) : null}
                                </span>
                            ) : (
                                <span className="flex justify-center items-center gap-1">
                                    {t("follow")} +
                                    {loading ? (
                                        <CgSpinner
                                            size="14px"
                                            className="animate-spin"
                                        />
                                    ) : null}
                                </span>
                            )}
                        </button>
                    </div>
                    <div className="flex flex-col gap-8 xl:max-w-[314px]">
                        <div className="block md:hidden">
                            <ClubProfileBookCentre clubData={clubData} setIsSlot={setIsSlot} />
                        </div>
                        <hr className="mx-4 md:hidden" />
                        <ClubProfileAbout
                            clubData={clubData}
                            following={following}
                            setFollowing={setFollowing}
                        />
                        <hr className="mx-4" />
                        <ClubProfileMap clubData={clubData} />
                        <hr className="mx-4" />
                        <div className="px-4">
                            <ProfileCredits credit={userCredit} />
                        </div>
                        {/* <hr className="mx-4" />
                        {!currentUser || clubData?._id === undefined ? (
                            <div className="py-5 rounded-2xl">
                                <div className="px-4">
                                    <h2 className="text-xl font-semibold text-left">
                                        Membership
                                    </h2>
                                    <div className="text-[15px] mt-3">
                                        <span
                                            onClick={handleRedirectLogin}
                                            className="underline cursor-pointer"
                                        >
                                            Log in
                                        </span>
                                        <span> to see memberships</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <ClubProfileMembership
                                clubId={clubData?._id}
                                currency={clubData?.currency}
                                timezone={clubData?.timezone}
                            />
                        )} */}
                    </div>
                </div>
                <div className="w-full md:w-[calc(50%-8px)] xl:w-[calc(100%-445px)] lg:max-w-[500px] md:mr-auto flex flex-col gap-10 mt-8 md:mt-0">
                    <div className="md:block hidden">
                        <ClubProfileBookCentre clubData={clubData} setIsSlot={setIsSlot} />
                    </div>
                    <ClubProfileActivities clubId={clubData?._id} setIsSlot={setIsSlot} selectedActivity={selectedActivity} setSelectedActivity={setSelectedActivity} />
                    {/* <TrainingComponent /> */}
                    {/* <TournamentComponent /> */}
                    {/* <MatchComponent /> */}
                </div>
            </div>
        </div>
    );
};

export default ClubProfileContent;
