// @ts-nocheck

import { t } from "i18next";
import { cancellationTime } from "../../Utilities/utils";

const SubTotalComponent = ({
    currentPrice,
    bookingData,
    discountAmount,
    voucher,
    membershipDiscount,
    membershipDiscountAmount,
    tournamentData,
    clubData,
    finalPrice,
    isSelected,
    taxValue,
    basePrice
}) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex justify-between gap-3 flex-wrap pt-[5px]">
                
                
                {membershipDiscount && membershipDiscount > 0 ? (
                    <div className="flex gap-2 flex-col">
                    <p className="text-[22px] flex-wrap text-left">{t("SubTotalComponent.subtotal")}
                </p>
                        <p className="text-[14px] flex-wrap text-left">
                            ({t("SubTotalComponent.membershipDiscount")} {membershipDiscount}%)
                        </p>
                        </div>
                    ) : null}
                
                <h3 id="modalSubTotalAmount" className="flex gap-2 flex-wrap font-semibold text-[22px]">
                    {membershipDiscount && membershipDiscount > 0  && membershipDiscountAmount && membershipDiscountAmount > 0 ? 
                    <span>{discountAmount
                        ? (discountAmount + currentPrice).toFixed(2)
                        : currentPrice.toFixed(2)}{" "}{bookingData?.currency}</span>
                         : null}
                </h3>
            </div>
            {discountAmount ? (
                <>
                    <div className="flex justify-between gap-3 flex-wrap pt-[5px]">
                    <div className="flex gap-2 flex-col">
                        <p className="text-[22px] flex-wrap text-left">
                            {t("SubTotalComponent.afterDiscount")}
                        </p>
                        {voucher?.amount_in_percentage && voucher?.amount_in_percentage > 0 ? (
                        <p className="text-[14px] flex-wrap text-left">
                            ({t("SubTotalComponent.promoCodeDiscount")} {voucher?.amount_in_percentage}%)
                        </p>
                    ) : null}
                    </div>
                        <h3 className="font-semibold text-[22px]">
                            {currentPrice.toFixed(2)} {bookingData?.currency}
                        </h3>
                    </div>
                </>
            ) : null}
            {taxValue > 0 ? (
                <>
                    <div className="flex justify-between gap-3 flex-wrap pt-[5px]">
                    <div className="flex gap-2 flex-col">
                        <p className="text-[22px] flex-wrap text-left">
                            {t("SubTotalComponent.tax")}
                        </p>
                        {clubData?.salestax?.salestax_percentage && clubData?.salestax?.salestax_percentage > 0 && isSelected !== "credit" && clubData?.salestax?.salestax_on_top ? (
                        <p className="text-[14px] flex-wrap text-left">
                            ({t("SubTotalComponent.salesTax")} {clubData?.salestax?.salestax_percentage}%)
                        </p>
                    ) : null}
                    </div>
                        <h3 className="font-semibold text-[22px]">
                            {taxValue.toFixed(2)} {bookingData?.currency}
                        </h3>
                    </div>
                </>
            ) : null}
                    <div className="flex justify-between gap-3 flex-wrap pt-[5px]">
                    <div className="flex gap-2 flex-col">
                        <p className="text-[22px] flex-wrap text-left">
                            {t("SubTotalComponent.total")}
                        </p>
                    </div>
                        <h3 className="flex gap-2 flex-wrap font-semibold text-[22px]">
                            {(membershipDiscount && membershipDiscount > 0 && membershipDiscountAmount && membershipDiscountAmount > 0) || (voucher?.amount_in_percentage && voucher?.amount_in_percentage > 0) || (clubData?.salestax?.salestax_percentage && clubData?.salestax?.salestax_percentage > 0 && isSelected !== "credit" && clubData?.salestax?.salestax_on_top) ? <span>{finalPrice.toFixed(2)} {bookingData?.currency}</span> : null}
                            <span className={`${((membershipDiscount && membershipDiscount > 0 && membershipDiscountAmount && membershipDiscountAmount > 0) || (voucher?.amount_in_percentage && voucher?.amount_in_percentage > 0) || (clubData?.salestax?.salestax_percentage && clubData?.salestax?.salestax_percentage > 0 && isSelected !== "credit" && clubData?.salestax?.salestax_on_top)) ? "line-through" : ""}`}>
                            {basePrice}{" "}{bookingData?.currency}
                    </span>
                        </h3>
                    </div>
            <hr className="w-full" />
            <div className="flex justify-between gap-3 flex-wrap text-[15px]">
                <p>{t("SubTotalComponent.cancelPolicy")}</p>
                <p className="text-[#FF3A3A] font-medium">
                    {tournamentData?.cancellation_period ? tournamentData?.cancellation_period : cancellationTime(clubData?.default_cancellation_time_booking)} {`${t("uCanNotCancelPost")} `}
                    <a
                        style={{
                            color: "rgb(0, 11, 41)",
                            textDecoration: "underline"
                        }}
                        href="https://padelmates.se/en/privacy-policy-1"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("SubTotalComponent.readMore")}
                    </a>
                </p>
            </div>
            <div className="flex justify-between gap-3 flex-wrap text-[15px]">
                <p>{t("SubTotalComponent.cancellationFee")}:</p>
                <div className="text-end">
                    {bookingData?.currency === "SEK" ? (
                        <p className="font-medium text-end">
                            {`${bookingData?.cancellation_fee} ${bookingData?.currency}`}
                        </p>
                    ) : (
                        <p className="font-medium text-end">
                            {`${bookingData?.currency} ${bookingData?.cancellation_fee}`}
                        </p>
                    )}
                    <a
                        style={{
                            color: "rgb(0, 11, 41)",
                            textDecoration: "underline"
                        }}
                        href="https://padelmates.se/en/privacy-policy-1"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("SubTotalComponent.cancellationPolicy")}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SubTotalComponent;
