import React, { useContext, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getAuth, signOut } from "firebase/auth";
import { FaUserCircle } from "react-icons/fa";
import { Notifications } from "./Notifications";
import { Menu, Popover, Transition } from "@headlessui/react";
import { IoNotificationsSharp } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import { useAtom } from "jotai";
import { userName } from "../../../Utilities/username";
import { getUserInfo } from "../../../Api/api";

export const MenuUser = ({
    userNotifications = [],
    onNotificationIconClick,
    isLoading = false,
    isForDesktopMenu = true,
    onNotificationItemClick,
    onSeeAllNotificationsInInbox
}) => {
    const currentUser = useContext(AuthContext);
    const auth = getAuth();
    const navigate = useNavigate();
    const [user, setuser] = useAtom(userName);

    useEffect(() => {
        getUserInfo().then((res) => {
            setuser(res?.user);
        });
    }, []);

    const { t } = useTranslation();

    const SignOut = () => {
        signOut(auth)
            .then(() => {
                localStorage.clear();
                navigate("/");
            })
            .catch(() => {});
    };

    return (
        <div className="flex flex-col lg:flex-row lg:gap-2 relative">
            <Popover
                className={`relative lg:items-center lg:justify-center flex ${
                    isForDesktopMenu ? "" : "hidden"
                }`}
            >
                <Popover.Button className="outline-none px-2 py-2 lg:px-0 lg:py-0 w-full lg:w-auto flex items-center gap-2">
                    <IoNotificationsSharp
                        onClick={() => {
                            onNotificationIconClick();
                        }}
                        className="w-5 h-5 lg:w-[23px] lg:h-[28px] hover:scale-110 transition duration-200"
                    />
                </Popover.Button>
                <Popover.Panel className="absolute -right-12 top-16 lg:right-0 w-[360px] sm:w-96 z-10">
                    <Notifications
                        userNotifications={userNotifications}
                        isLoading={isLoading}
                        onNotificationItemClick={onNotificationItemClick}
                        onSeeAllNotificationsInInbox={
                            onSeeAllNotificationsInInbox
                        }
                    />
                </Popover.Panel>
            </Popover>
            <div className={isForDesktopMenu ? "hidden lg:block" : ""}>
                <Menu as="div" className="flex items-center relative text-left">
                    <div className="flex items-center">
                        <Menu.Button className="inline-flex w-full justify-center font-medium text-white outline-none">
                            <div id="userProfileButton" className="flex flex-wrap text-white items-center gap-2 p-2 lg:max-w-[300px] xl:max-w-[500px] 2xl:max-w-[700px mt-0 lg:mt-0 max-w-[200px] text-left hover:bg-primary outline-none rounded-md hover:text-secondary lg:hover:bg-transparent lg:hover:text-white lg:hover:scale-105 lg:transition lg:duration-200">
                                {!user?.profilePic ? (
                                    <FaUserCircle className="h-[30px] w-[30px]" />
                                ) : (
                                    <img
                                        src={user?.profilePic}
                                        className="w-[30px] h-[30px] rounded-full"
                                        alt="avatar"
                                    />
                                )}
                                <p className="break-all lg:text-base text-sm">
                                    {user?.name ? user?.name : currentUser?.email}
                                </p>
                            </div>
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute top-0 -right-2 lg:-right-0 mt-16 lg:mt-12 w-48 origin-top-right divide-y divide-gray-100 bg-secondary text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-2 py-4">
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/profile"
                                            style={({ isActive }) => {
                                                return {
                                                    color: isActive
                                                        ? "#43EC9B"
                                                        : "white"
                                                };
                                            }}
                                        >
                                            <button
                                                id="userProfileAccount"
                                                className={`${
                                                    active
                                                        ? "bg-primary text-secondary"
                                                        : ""
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                            >
                                                {t("MenuUser.profile")}
                                            </button>
                                        </NavLink>
                                    )}
                                </Menu.Item>

                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/account-settings"
                                            style={({ isActive }) => ({
                                                color: isActive
                                                    ? "#43EC9B"
                                                    : "white"
                                            })}
                                        >
                                            <button
                                                id="accountsSettings"
                                                className={`${
                                                    active
                                                        ? "bg-primary text-secondary"
                                                        : ""
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                            >
                                                {t("MenuUser.accountSettings")}
                                            </button>
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <NavLink
                                            to="/payment-history"
                                            style={({ isActive }) => ({
                                                color: isActive
                                                    ? "#43EC9B"
                                                    : "white"
                                            })}
                                        >
                                            <button
                                                className={`${
                                                    active
                                                        ? "bg-primary text-secondary"
                                                        : ""
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                            >
                                                {t("MenuUser.paymentHistory")}
                                            </button>
                                        </NavLink>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={() => SignOut()}
                                            className={`${
                                                active
                                                    ? "bg-primary text-secondary"
                                                    : ""
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-secondary`}
                                        >
                                            {t("MenuUser.signOut")}
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
};
