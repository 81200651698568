import React from "react";
import { useTranslation } from "react-i18next";

interface PopupProps {
    onDelete: () => void;
    onCancel?: () => void;
    setIsOpen: any;
    isOpen: Boolean;
    message: string;
    title: string;
}

const Popup: React.FC<PopupProps> = ({
    onDelete,
    isOpen,
    setIsOpen,
    title,
    message
}) => {
    const { t } = useTranslation();
    
    const handleDelete = () => {
        onDelete();
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    return isOpen ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#00000080] z-[4000] px-2">
            <div className="bg-white p-5 rounded flex flex-col gap-5 items-center justify-center px-10">
                <h2 className="text-xl font-bold">{title}</h2>
                <p className="text-lg">{message}</p>
                <div className="flex items-center justify-center gap-5 flex-wrap">
                    <button
                        id="handleDeleteButton"
                        className="bg-secondary hover:bg-red-600 text-white rounded py-2 px-10 border-none transform duration-200"
                        onClick={handleDelete}
                    >
                        {t("yes")}
                    </button>
                    <button
                        id="handleCancelButton"
                        className="bg-white hover:bg-primary hover:border-primary text-secondary border border-secondary rounded py-2 px-10"
                        onClick={handleCancel}
                    >
                        {t("no")}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default Popup;
