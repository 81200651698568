import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { useBookingAtom, useClubAtom } from "../../Utilities/clubStore";
import moment from "moment";
import {
    calculateAvailability,
    getAllAvailableHours,
    getSelectableHours
} from "../../Api/api";
import { useTranslation } from "react-i18next";

type BookSlotType = {
    id: string;
    courtName: string;
    courtType: string;
    duration: string;
    startTime: string;
    endTime: string;
    price: string;
};

interface BookSlotProps {
    slots?: any;
    key?: string;
    date: any;
    menuDivActive: boolean;
    day: string;
    jsonData: any;
    timezone: string;
    time: string;
    allSlots?: any;
    originalCourts?: any;
    setIsSlot?: Dispatch<SetStateAction<boolean>>;
}

const BookSlot: React.FC<BookSlotProps> = ({
    slots,
    key,
    date,
    menuDivActive,
    day,
    jsonData,
    timezone,
    time,
    allSlots,
    originalCourts,
    setIsSlot
}) => {
    const [courtDivActive, setCourtDivActive] = useState<boolean[]>();
    const [slotsLength, setSlotsLength] = useState(0);
    const [availableSlot, setAvailableSlot] = useState<any>({});
    const [bookingAtom, setBookingAtom] = useBookingAtom();
    const [updateSelectedSlot, setUpdateSelectedSlot] = useState<string>();
    const [club, setClub] = useClubAtom();
    const { t } = useTranslation();

    function generateBooleanArray(length: number) {
        const booleanArray = [];
        for (let i = 0; i < length; i++) {
            booleanArray.push(true);
        }
        return booleanArray;
    }

    useEffect(() => {
        // const hours = getSelectableHours(getAllAvailableHours([slots]));
        // const _availableSlot = calculateAvailability(
        //     slots.available_slots,
        //     time,
        //     hours
        // );

        // setAvailableSlot(_availableSlot);
        setAvailableSlot({});
        let newSlot = "";
        if (time) {
            const myArray = time.split(",");
            newSlot = myArray[1];
            setUpdateSelectedSlot(myArray[0]);
        }

        setTimeout(() => {
            const _availableSlot = getObjectsWithStartTime(
                allSlots,
                newSlot ? newSlot : time
            );

            if (_availableSlot) {
                setSlotsLength(getJsonKeyCount(_availableSlot));
                setAvailableSlot(_availableSlot);
                setCourtDivActive(
                    generateBooleanArray(getJsonKeyCount(_availableSlot))
                );
            } else {
                setAvailableSlot({});
                setCourtDivActive(generateBooleanArray(slotsLength));
            }
        }, 500);

        // const isAvailable = Object.values(_availableSlot).some(
        //     (slot) => slot.isAvailable
        // );

        // if (isAvailable) {
        //     setCourtDivActive(true);
        // } else {
        //     setCourtDivActive(false);
        // }
    }, [time]);

    function getJsonKeyCount(_availableSlot: any) {
        // Initialize count
        let count = 0;

        // Loop through the object
        for (const key in _availableSlot) {
            count++;
        }
        return count;
    }

    function getObjectsWithStartTime(data: any, startTime: string) {
        const result: any = {};

        for (const obj of data) {
            if (obj.startTime === startTime) {
                if (!result[obj.courtName]) {
                    result[obj.courtName] = [];
                }
                result[obj.courtName].push(obj);
            }
        }

        return result;
    }

    const getFacilities = (court: any) => {
        const facilities = [
            court.indoor ? t("indoor") : t("outdoor"),
            court.double ? t("double") : t("single"),
            court.sport.badminton ? t("Badminton") : "",
            court.sport.squash ? t("Squash") : "",
            court.sport.tennis ? t("Tennis") : "",
            court.sport.padel ? t("Padel") : "",
            court.sport.pickleball ? t("Pickleball") : ""
        ].filter((item) => item !== "");

        return facilities;
    };

    const selectedDateTime = moment.tz(
        `${date} ${updateSelectedSlot}`,
        "DD/MM/YYYY HH:mm",
        timezone
    );

    const daySlotsExpired = selectedDateTime
        .tz(timezone)
        .isBefore(moment().tz(timezone));

    const foundSlot = slots?.available_slots?.find(
        (slot: any) => slot.hours?.includes(updateSelectedSlot)
    );

    const end = moment.tz(foundSlot?.end_datetime, timezone);
    const diff = end.diff(selectedDateTime, "minutes");

    const availableSlotOld: { [key: string]: boolean } = {
        "60": !daySlotsExpired && diff - 60 >= 0 && diff - 60 !== 30,
        "90": !daySlotsExpired && diff - 90 >= 0 && diff - 90 !== 30,
        "120": !daySlotsExpired && diff - 120 >= 0 && diff - 120 !== 30,
        "180": !daySlotsExpired && diff - 180 >= 0 && diff - 180 !== 30
    };

    const price = foundSlot?.price || 0;

    useEffect(() => {
        // console.log("loading => bookingAtom", bookingAtom);
    }, [bookingAtom]);

    const toggleCourtClass = (index: number, value: boolean) => {
        if (!!courtDivActive) {
            const updatedState = [...courtDivActive]; // Create a copy of the state array
            updatedState[index] = value;
            setCourtDivActive(updatedState);
        }
    };

    return (
        <div className="pb-3">
            {Object.keys(availableSlot).map((key, index) => {
                let courtData: any = {};
                let courtItem: any = {};
                originalCourts?.map((val: any) => {
                    if (val.name === key) {
                        courtData = getFacilities(val);
                        courtItem = val;
                    }
                });

                return (
                    <>
                        {!!index && <hr className="w-full" />}
                        <div className="flex flex-wrap items-center justify-between gap-2 pt-4 pb-4">
                            <div>
                                <h4 id="profileCourtName" className="text-base font-semibold">
                                    {key}
                                </h4>
                                <p className="text-xs">
                                    {courtData?.join(" | ")}
                                </p>
                            </div>
                            <button>
                                {!!courtDivActive && !courtDivActive[index] ? (
                                    <SlArrowDown
                                        size="16px"
                                        onClick={() =>
                                            toggleCourtClass(index, true)
                                        }
                                    />
                                ) : (
                                    <SlArrowUp
                                        size="16px"
                                        onClick={() =>
                                            toggleCourtClass(index, false)
                                        }
                                    />
                                )}
                            </button>
                        </div>
                        {/* Find your ideal {day} Time Slot on {date} at {time} */}
                        {!!courtDivActive && courtDivActive[index] && (
                            <div className="flex flex-col gap-2 pb-4">
                                {availableSlot[key]?.length ? (
                                    availableSlot[key]?.map((item: any, inx: number) => (
                                        <div
                                            className={
                                                !courtDivActive
                                                    ? "hidden"
                                                    : "flex justify-between items-center gap-2 flex-wrap"
                                            }
                                            key={key}
                                        >
                                            <div>
                                                <h5 className="font-medium text-base">
                                                    {item?.duration} min
                                                </h5>
                                                <p className="text-xs">
                                                    <span>
                                                        {updateSelectedSlot}
                                                    </span>{" "}
                                                    -{" "}
                                                    <span>
                                                        {moment(
                                                            updateSelectedSlot,
                                                            "HH:mm"
                                                        )
                                                            .add(
                                                                item?.duration,
                                                                "minutes"
                                                            )
                                                            .format("HH:mm")}
                                                    </span>
                                                </p>
                                            </div>
                                            <button
                                                id={`price-${index}-${inx}`}
                                                className="bg-secondary text-white text-[13px] px-2 py-1 w-[126px] min-h-[31px] rounded-full flex items-center justify-center hover:bg-primary hover:text-secondary transform duration-200"
                                                onClick={() => {
                                                    setClub({
                                                        ...club,
                                                        startTime:
                                                            availableSlotOld.start_datetime,
                                                        endTime:
                                                            availableSlotOld.end_datetime
                                                    });
                                                    const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
                                                    localStorage.setItem("selectedDate", formattedDate);
                                                    setBookingAtom({
                                                        ...bookingAtom,
                                                        page: 1,
                                                        availableSlotState:
                                                            availableSlotOld,
                                                        selectedCourt: courtItem,
                                                        startTime: moment(updateSelectedSlot,
                                                            "HH:mm").format("HH:mm"),
                                                        endTime: moment(
                                                            updateSelectedSlot,
                                                            "HH:mm"
                                                        )
                                                            .add(
                                                                item?.duration,
                                                                "minutes"
                                                            )
                                                            .format("HH:mm"),
                                                        show: !bookingAtom.show,
                                                        basePrice: item?.price,
                                                        price: Number(
                                                            item?.price
                                                        ),
                                                        mins: item?.duration,
                                                        clubActivity: item,
                                                    });
                                                    setIsSlot && setIsSlot(true);
                                                }}
                                            >
                                                {t("book")}{" "}{item?.price?.toFixed(2)}{" "}
                                                {jsonData?.currency}
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <>
                                        <p>
                                            {t("noAvailableSlotFor")}{" "}
                                            <b>{updateSelectedSlot}</b>{" "}
                                        </p>
                                        <p>
                                            {t("selectAnotherDate")}
                                        </p>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                );
            })}
        </div>
    );
};

export default BookSlot;
