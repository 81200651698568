import moment from "moment-timezone";
import React from "react";
import dummyProfileNew from "../../widgets/bookingWidget/bookingModal/images/dummyProfileNew.png";
import { t } from "i18next";

const ActivitiesTournamentCard = ({
    clubActivity,
    page,
    clubName
}: {
    clubActivity: any;
    page?: string;
    clubName: string;
}) => {
    const { court_info } = clubActivity;

    const courtType =
        court_info && court_info.length && court_info[0]?.indoor
            ? "indoor"
            : "outdoor";
    const isDouble =
        court_info && court_info.length && court_info[0]?.double
            ? "double"
            : "single";
    const sports =
        court_info && court_info.length && court_info[0]?.sport
            ? Object.entries(court_info[0]?.sport)
                  .filter(([key, value]) => value)
                  .map(([key]) => key)
            : [];

    const facilities =
        sports && sports.length ? [courtType, isDouble, ...sports] : [];

    const facilitiesString = facilities
        .filter((item) => item !== null)
        .join(" | ");

    return (
        <div>
            <div className="w-full py-4 my-2 px-4 border border-secondary rounded-[10px] cursor-pointer h-full">
                <div className="flex gap-6 sm:gap-8 flex-wrap items-center justify-between h-full">
                    <div className="flex flex-wrap gap-8 items-center h-full">
                        <img
                            src={
                                clubActivity?.banner_images_url?.[0]?.bannerImg
                                    ? clubActivity?.banner_images_url[0]?.bannerImg
                                    : clubActivity?.activity === "Tournament"
                                    ? "/images/tournamentCardBackgroundNew.png"
                                    : clubActivity?.activity === "Training"
                                    ? "/images/trainingCardBackground.png"
                                    : `/images/padelMatesImage.jpg`
                            }
                            alt="Activity Banner"
                            className="w-full sm:w-[280px] h-[140px] rounded-[8px] shadow shadow-gray-500 bg-secondary object-cover"
                        />
                        <div className="flex flex-col gap-1 max-w-[300px] break-words">
                            <p
                                id="activityTypeTitle"
                                className="text-sm font-medium capitalize"
                            >
                                {clubActivity?.title}
                            </p>
                            <p id="timeText" className="text-sm font-medium">
                                {clubActivity.start_datetime
                                    ? moment
                                          .tz(
                                              clubActivity.start_datetime,
                                              clubActivity?.timezone
                                          )
                                          .format("ddd")
                                    : ""}{" "}
                                {clubActivity.start_datetime
                                    ? moment
                                          .tz(
                                              clubActivity.start_datetime,
                                              clubActivity?.timezone
                                          )
                                          .format("MM/DD HH:mm")
                                    : ""}{" "}
                                -{" "}
                                {clubActivity.start_datetime
                                    ? moment
                                          .tz(
                                              clubActivity.stop_datetime,
                                              clubActivity?.timezone
                                          )
                                          .format("HH:mm")
                                    : ""}
                            </p>
                            <h4 className="text-xl font-semibold">
                                {clubName}
                            </h4>
                            <h4 className="text-xl font-semibold">
                                {clubActivity?.court_info?.[0]?.name}
                            </h4>
                            <p className="text-[15px] text-[#666B78] capitalize">
                                {facilitiesString}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 xl:gap-12 font-medium xl:items-end h-full xl:justify-between">
                        <div className="flex gap-2 items-center flex-wrap">
                            <img src="/images/trophy.svg" alt="trophy" />
                            <p
                                id="activityTypeTitle"
                                className="text-xs font-medium uppercase"
                            >
                                {clubActivity?.activity === "Training" ? `${t("Profile.coaching")}` : clubActivity?.activity}{" "}
                                {clubActivity?.tournament_type}
                            </p>
                        </div>
                        <div className="flex flex-col gap-3 font-medium xl:items-end">
                            <div className="ml-2 flex">
                                {clubActivity?.tournament_type === "team_americano" ? <>{clubActivity?.players?.slice(0, 4)?.map((group: any[], groupIndex: number) => {
                                    const secondPlayer = group?.[1]; // Access the second object in the sub-array
                                    return secondPlayer?.profilePic ? (
                                        <img
                                            key={groupIndex}
                                            src={secondPlayer?.profilePic}
                                            alt="Player avatar"
                                            className="max-w-6 min-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400"
                                        />
                                    ) : (
                                        <img src={dummyProfileNew} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" />
                                    );
                                })}</> : <>{clubActivity?.players?.slice(0, 4)?.map(
                                    (
                                        player: {
                                            profilePic: string | undefined;
                                        },
                                        index: React.Key | null | undefined
                                    ) => (
                                        player?.profilePic ? <img src={player?.profilePic} alt="Player avatar" className="max-w-6 min-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> :
                                        <img src={dummyProfileNew} alt="Player avatar" className="min-w-6 max-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" />
                                    )
                                )}</>}
                            </div>
                            <div className="flex gap-3 items-center flex-wrap">
                                <div className="flex gap-2 flex-wrap items-center">
                                    <img
                                        src="/images/badge.svg"
                                        alt="badge"
                                        className={`${
                                            page === "activities"
                                                ? "w-6"
                                                : "w-6"
                                        }`}
                                    />
                                    <p
                                        className={`${
                                            page === "activities"
                                                ? "text-sm"
                                                : "text-sm"
                                        } font-medium`}
                                    >
                                        {clubActivity?.min_level}-
                                        {clubActivity?.max_level}
                                    </p>
                                </div>
                                <div
                                    className={`text-white ${
                                        page === "activities"
                                            ? "text-[13px]"
                                            : "text-[13px]"
                                    } h-[31px] flex gap-[1px] items-center cursor-pointer`}
                                >
                                    <button
                                        className={`${
                                            page === "activities"
                                                ? "text-[13px] rounded-l-full h-[31px] px-3"
                                                : "text-[13px] rounded-l-full h-[31px] px-2.5"
                                        } bg-secondary font-medium`}
                                    >
                                        {clubActivity?.current_no_of_players}/
                                        {clubActivity?.no_of_players}
                                    </button>
                                    <button
                                        id="priceCard"
                                        className={`${
                                            page === "activities"
                                                ? "text-[13px] rounded-r-full h-[31px] px-3"
                                                : "text-[13px] rounded-r-full h-[31px] px-2.5"
                                        } bg-secondary font-semibold`}
                                    >
                                        {clubActivity?.payment_per_person !== 0
                                            ? clubActivity?.payment_per_person?.toFixed(
                                                  0
                                              )
                                            : 0}{" "}
                                        {clubActivity?.currency
                                            ? clubActivity?.currency
                                            : "KR"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivitiesTournamentCard;
