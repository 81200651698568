import React from "react";
import { useTranslation } from "react-i18next";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { Store } from "react-notifications-component";

const OnboardingSports = ({
    setCurrentStep,
    currentStep,
    selectedSports,
    setSelectedSports,
    enableRatingModal,
    setEnableRatingModal,
    setCurrentSport
}: {
    setCurrentStep: any;
    currentStep: number;
    selectedSports: any;
    setSelectedSports: any;
    enableRatingModal: boolean;
    setEnableRatingModal: any;
    setCurrentSport: any;
}) => {
    const sports = [
        { id: 1, name: "Padel" },
        { id: 2, name: "Tennis" },
        { id: 3, name: "Badminton" },
        { id: 4, name: "Squash" },
        { id: 5, name: "Pickleball" }
    ];

    const { t } = useTranslation();

    const handleSportsSelect = (sportsName: string) => {
        const index = selectedSports.indexOf(sportsName);

        if (index !== -1) {
            // Sport exists in the array, so remove it
            const updatedArray = [...selectedSports];
            updatedArray.splice(index, 1);
            setSelectedSports(updatedArray);
        } else {
            // Sport doesn't exist, so add it
            setSelectedSports((prevArray: any) => [...prevArray, sportsName]);
            setEnableRatingModal(!enableRatingModal);
            setCurrentSport(sportsName);
        }
    };

    return (
        <div className="text-center p-10 w-[90%] md:w-[40%] bg-[#FBFBFB] rounded-3xl shadow">
            <h3 className="mb-10 font-bold text-xl text-secondary text-center">
                {t("areYouInterestedSports")} <br />
                <span className="text-lg font-normal mt-2">
                    ( {t("youCanChooseMore")} )
                </span>
            </h3>
            <ul className="grid w-full gap-6 md:grid-cols-3">
                {sports.map((sport: { id: number; name: string }) => (
                    <div className="flex flex-col" key={sport.id}>
                        <li onClick={() => handleSportsSelect(sport.name)}>
                            <label
                                className={
                                    selectedSports?.includes(sport.name)
                                        ? "flex items-center justify-center w-full p-2 text-secondary bg-primary rounded-full cursor-pointer"
                                        : "flex items-center justify-center w-full p-2 text-secondary bg-white border border-secondary rounded-full cursor-pointer"
                                }
                            >
                                <div className="w-full text-lg">
                                    {sport.name}
                                </div>
                            </label>
                        </li>
                    </div>
                ))}
            </ul>
            <div className="mt-20 flex justify-between items-center">
                <button
                    className="font-semibold outline-none rounded-full bg-white text-secondary border border-secondary w-auto px-10 py-3"
                    onClick={() => {
                        setCurrentStep((prev: number) => prev - 1);
                    }}
                >
                    <GrLinkPrevious />
                </button>
                <button
                    className="font-semibold outline-none rounded-full bg-white text-secondary border border-secondary w-auto px-10 py-3"
                    onClick={() => {
                        if (selectedSports.length) {
                            setCurrentStep(2);
                        } else {
                            Store.addNotification({
                                title: "Failed",
                                message: "please select sports first",
                                type: "danger",
                                container: "center",
                                animationIn: [
                                    "animate__animated",
                                    "animate__fadeIn"
                                ],
                                animationOut: [
                                    "animate__animated",
                                    "animate__fadeOut"
                                ],
                                dismiss: {
                                    duration: 3000
                                }
                            });
                        }
                    }}
                >
                    <GrLinkNext />
                </button>
            </div>
        </div>
    );
};

export default OnboardingSports;
