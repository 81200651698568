// @ts-nocheck

import React from "react";

import CourtComponent from "./CourtComponent";
import { t } from "i18next";
import { FiDownload } from "react-icons/fi";

const BookingDetails = ({
    startTime,
    finalPrice,
    minutes,
    endTime,
    bookingData,
    selectedCourt,
    showBookingDetails,
    accessCode,
    isBooked,
    receiptUrl,
    isTournamentType,
    isTrainingType,
    bookingStart,
    timeZone,
    isSlot,
    startDateTime,
    stopDateTime
}) => {
    return (
        <div>
            {showBookingDetails && (
                <div className="flex justify-between items-center gap-5 flex-wrap py-5">
                    <h3 className="text-2xl font-semibold">
                        {t("BookingDetails.details")}
                    </h3>
                </div>
            )}
            <hr className="w-full mx-auto" />
            <CourtComponent
                startTime={startTime}
                isBooked={isBooked}
                minutes={minutes}
                endTime={endTime}
                bookingData={bookingData}
                selectedCourt={selectedCourt}
                accessCode={accessCode}
                bookingStart={bookingStart}
                timeZone={timeZone}
                isSlot={isSlot}
                startDateTime={startDateTime}
                stopDateTime={stopDateTime}
            />
            <hr className="w-full mx-auto" />
            {showBookingDetails && bookingData && (
                <div>
                    {isTournamentType === true ||
                        (isTrainingType === true && (
                            <>
                                <div className="flex justify-between items-center gap-5 flex-wrap py-4 text-xl">
                                    <div className="">Pay for</div>
                                    <div className="font-medium">
                                        {isTournamentType === true
                                            ? "Tournament"
                                            : t("Profile.coaching")}
                                    </div>
                                </div>
                                <hr className="w-full mx-auto" />
                            </>
                        ))}
                    <div className="flex justify-between items-center gap-5 flex-wrap py-4 text-xl">
                        <div className="">{t("Profile.totalPayment")}</div>
                        <div className="font-medium">
                            {finalPrice?.toFixed(2)} {bookingData?.currency}
                        </div>
                    </div>
                    <hr className="w-full mx-auto" />
                    <div className="flex justify-between items-center gap-5 flex-wrap py-4">
                        <div className="text-xl font-medium">Receipt</div>
                        <div className="text-xl font-medium">
                            <a
                                href={receiptUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p className="text-black hover:scale-105 transform duration-200">
                                    <FiDownload className="inline" size={25} />
                                </p>
                            </a>
                        </div>
                    </div>
                    <hr className="w-full mx-auto" />
                </div>
            )}
        </div>
    );
};

export default BookingDetails;
