/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import Switch from "react-switch";
import { ProfileFormData } from "./Types.types";
import { useTranslation } from "react-i18next";
// import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Modal } from "../ui/Modal";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { updateUserHideProfile, uploadUserImage } from "../../Api/api";
import { BsInfoCircle } from "react-icons/bs";
import { Store } from "react-notifications-component";
import UploadImage from "./UploadImage";
import { useAtom } from "jotai";
import { userName } from "../../Utilities/username";

const ProfileManagement = ({
    setShowComponent,
    userData,
    saveProfile
}: {
    setShowComponent: Dispatch<SetStateAction<string>>;
    userData: ProfileFormData | undefined;
    saveProfile: (data: ProfileFormData) => void;
}) => {
    const { t } = useTranslation();
    // const [showPassword, setShowPassword] = useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] =
        useState(false);
    const [isResetSent, setIsResetSent] = useState(false);
    const [hideProfile, setHideProfile] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [error, setError] = useState("");
    const [profilePic, setProfilePic] = useState<File | null>(null);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneNumberError] = useState("");
    const [user, setuser] = useAtom(userName);

    const [formData, setFormData] = useState<ProfileFormData>({
        name: userData?.name ?? "",
        phone: userData?.phone ?? ""
    });

    // Validation for name and phone
    const nameRegex = /^[a-zA-Z\s]+$/; // Only allows letters and spaces
    const phoneRegex = /^\+?[0-9\-()]+$/; // Only allows digits

    useEffect(() => {
        if (userData) {
            const { email = "", phone = "", name } = userData;
            setFormData({
                email,
                phone,
                name
            });
        }
    }, [userData]);

    const handleFormInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleUpload = async (profilePic: any) => {
        if (!profilePic) {
            setUploadError(t("AccountSettings.selectImageError"));
            return;
        }

        const formData = new FormData();
        formData.append("profilePic", profilePic);

        try {
            const res = await uploadUserImage(formData);
            Store.addNotification({
                title: null,
                message: t("AccountSettings.uploadSuccessful"),
                type: "success",
                insert: "bottom",
                container: "bottom-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2000
                }
            });
            if (res?.success && res?.data?.profilePic) { 
                setuser({ ...user, profilePic: res?.data?.profilePic })
            }
            return res;
        } catch (error) {
            console.error("Upload failed:", error);
            setUploadError(t("AccountSettings.imageUploadFail"));
        }
    };

    useEffect(() => {
        if (
            formData?.phone &&
            (formData?.phone?.trim()?.length < 10 ||
                !phoneRegex.test(formData?.phone?.trim()))
        ) {
            setPhoneNumberError(`${t("BookingModal.invalidPhoneNumber")}!`);
        } else {
            setPhoneNumberError("");
        }
    }, [formData.phone, t]);

    useEffect(() => {
        if (!formData?.name?.trim()) {
            setNameError(`${t("BookingModal.nameRequired")}!`);
        } else if (formData?.name && !nameRegex.test(formData?.name?.trim())) {
            setNameError(`${t("BookingModal.invalidName")}!`);
        } else {
            setNameError("");
        }
    }, [formData.name, t]);

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (
            formData?.phone &&
            (formData?.phone?.trim()?.length < 10 ||
                !phoneRegex.test(formData?.phone?.trim()))
        ) {
            setPhoneNumberError(`${t("BookingModal.invalidPhoneNumber")}!`);
        } else if (!nameRegex.test(formData?.name?.trim())) {
            setNameError(`${t("BookingModal.invalidName")}!`);
        } else {
            let isFormValid = true;
            if (!formData.name.trim()) {
                isFormValid = false;
            }

            if (isFormValid) {
                saveProfile(formData);
            } else {
            }
        }
    };

    // const togglePasswordVisibility = () => {
    //     setShowPassword((prevShowPassword) => !prevShowPassword);
    // };

    const handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await sendPasswordResetEmail(auth, userData?.email as string);
            setIsResetSent(true);
            setError("");
        } catch (error: any) {
            setIsResetSent(false);
            setError(error.message);
        }
    };

    const toggleInfo = () => {
        setShowInfo(!showInfo);
    };

    return (
        <div className="text-center py-5 px-2 sm:px-[22px] bg-[#FBFBFB] rounded-3xl shadow">
            <h2 className="text-2xl font-semibold text-center">
                {t("AccountSettings.profileTitle")}
            </h2>
            <div className="my-4 bg-[#000b2926] w-full h-[1px]" />
            <form onSubmit={handleFormSubmit} noValidate>
                <UploadImage
                    handleUpload={handleUpload}
                    uploadError={uploadError}
                />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mt-10">
                    <div className="relative py-2">
                        <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            {t("AccountSettings.labelUserName")}{" "}
                            <span className="text-red-400 text-xl align-middle">
                                *
                            </span>
                        </label>
                        <input
                            id="userName"
                            className={`min-w-[200px] w-full h-[50px] bg-white border-[1px] ${
                                !formData.name.trim() || nameError
                                    ? "border-red-400 outline-red-400"
                                    : "border-[#000b2999] outline-primary"
                            }  rounded-[50px] text-secondary font-normal p-4`}
                            type="text"
                            //   placeholder="User name"
                            name="name"
                            value={formData.name}
                            onChange={handleFormInputChange}
                        />
                        {/* <p
                            className={`ml-2 text-start mt-2 text-sm text-red-500  ${
                                formData.name.trim() ? " hidden " : "block"
                            }`}
                        >
                            {t("AccountSettings.labelNameErrorMessage")}
                        </p> */}
                        {nameError && (
                            <p className="text-red-500 mt-1 ml-2 text-xs text-left">
                                {nameError}
                            </p>
                        )}
                    </div>
                    <div className="relative py-2">
                        <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            {t("AccountSettings.labelEmail")}
                        </label>
                        <input
                            id="userEmail"
                            className="min-w-[200px] w-full h-[50px] bg-gray-100 border-[1px] border-[#000b2999] rounded-[50px] text-secondary font-normal p-4 outline-primary"
                            type="email"
                            // placeholder="User email"
                            name="email"
                            value={userData?.email ?? ""}
                            disabled
                        />
                    </div>
                    <div className="relative py-2">
                        <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            {t("AccountSettings.labelPhone")}
                        </label>
                        <input
                            id="userPhone"
                            className={`min-w-[200px] w-full h-[50px] bg-white border-[1px] ${
                                phoneError
                                    ? "border-red-400 outline-red-400"
                                    : "border-[#000b2999] outline-primary"
                            } rounded-[50px] text-secondary font-normal p-4`}
                            type="text"
                            // placeholder="Phone number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleFormInputChange}
                        />
                        {phoneError && (
                            <p className="text-red-500 mt-1 ml-2 text-xs text-left">
                                {phoneError}
                            </p>
                        )}
                    </div>
                    <div className="relative flex flex-col py-2">
                        {/* <label className="absolute bg-[#FBFBFB] text-[#000b2999] -top-1.5 left-[8px] text-sm px-1">
                            Password
                        </label>
                        <div className="flex items-center">
                            <input
                                className="min-w-[200px] w-full h-[50px] bg-white border-[1px] border-[#000b2999] focus:bg-white rounded-[50px] text-secondary font-normal pl-4 pr-10 outline-none"
                                type={showPassword ? "text" : "password"}
                                // placeholder="Password"
                                name="password"
                            />
                            {showPassword ? (
                                <BsEyeSlashFill
                                    className="w-4 h-4 -ml-8 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                />
                            ) : (
                                <BsEyeFill
                                    className="w-4 h-4 -ml-8 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                />
                            )}
                        </div> */}
                        <button
                            type="button"
                            onClick={() => {
                                setOpenChangePasswordModal(true);
                            }}
                            className="text-sm text-primary underline text-start mt-4"
                        >
                            {t("AccountSettings.changePasswordByClicking")}
                        </button>
                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="flex items-center relative">
                            <p className="generalLabel">
                                {t("AccountSettings.hideProfile")}
                            </p>
                            <div className="relative ml-2">
                                <button
                                    type="button"
                                    className="outline-none"
                                    onClick={() => {
                                        toggleInfo();
                                    }}
                                >
                                    <BsInfoCircle className="w-3.5 h-3.5" />
                                </button>
                                {showInfo && (
                                    <div className="absolute z-10 bg-[#c4d0ef] rounded text-secondary text-xs right-[calc(90% + 10px)] top-10 p-2 w-[180px] after:absolute after:-top-2 after:right-[calc(92%-10px)] 2xl:after:left-[calc(8%-10px)] after:-z-10 after:h-5 after:w-5 after:-rotate-45 after:bg-[#c4d0ef] after:content-['']">
                                        <div className="relative">
                                            <div className="absolute -left-3 top-0 w-0 h-0 border-t-3 border-r-3 border-transparent border-[#c4d0ef]"></div>
                                            <p
                                                onClick={() =>
                                                    setShowInfo(!showInfo)
                                                }
                                            >
                                                {t(
                                                    "AccountSettings.hideProfileInfo"
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <Switch
                                onColor="#43ec9b"
                                onHandleColor="#fff"
                                handleDiameter={15}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={20}
                                width={35}
                                className="react-switch mt-1"
                                id="material-switch"
                                onChange={(value: boolean) => {
                                    setHideProfile(value);
                                    updateUserHideProfile().then((data) => {});
                                }}
                                checked={hideProfile}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end flex-wrap gap-4 items-center mt-10 mb-6">
                    {/* <button className="rounded-full border border-secondary w-full max-w-[148px] min-h-[48px] py-1 px-3 font-semibold">
            Cancel
          </button> */}
                    <button
                        id="userInfoSave"
                        type="submit"
                        className="rounded-full w-full max-w-[148px] min-h-[48px] py-1 px-3 font-semibold bg-secondary text-white hover:bg-primary hover:text-secondary transform duration-200"
                    >
                        {t("AccountSettings.btnSave")}
                    </button>
                </div>
            </form>

            <Modal
                open={openChangePasswordModal}
                setOpen={setOpenChangePasswordModal}
            >
                <div className="bg-white p-5 lg:py-8 lg:px-16 font-semibold rounded-2xl w-full mx-auto">
                    {isResetSent ? (
                        <div className="mt-8 mb-5 text-center">
                            <p>
                                {t("Auth.emailSent")} {userData?.email}.
                            </p>
                            <p>{t("Auth.checkEmail")}</p>
                            <button
                                type="button"
                                onClick={() => {
                                    setOpenChangePasswordModal(false);
                                }}
                                className="border text-secondary border-secondary hover:bg-red-600 hover:border-red-600 rounded-3xl flex-1 h-[60px] w-[100px] mt-6 font-semibold hover:text-white"
                            >
                                {t("close")}
                            </button>
                        </div>
                    ) : (
                        <div>
                            <p className="mt-8 mb-5">
                                {t("AccountSettings.areYouSure")}
                            </p>
                            <div className="flex flex-wrap gap-5 mt-8 mb-5">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setOpenChangePasswordModal(false);
                                    }}
                                    className="border min-w-max px-4 text-secondary border-secondary hover:bg-red-600 hover:border-red-600 rounded-3xl flex-1 h-[60px] font-semibold hover:text-white"
                                >
                                    {t("PaymentComponent.cancel")}
                                </button>
                                <button
                                    type="submit"
                                    onClick={handleResetPassword}
                                    value={t("send")}
                                    className="bg-secondary min-w-max px-6 cursor-pointer
                                    text-white hover:bg-primary
                                    hover:text-secondary transform duration-200
                                    rounded-3xl flex-1 h-[60px] font-semibold"
                                >
                                    {t("MenuUser.reset")}
                                </button>
                            </div>
                            {error && (
                                <p className="text-red-500 text-center mt-6">
                                    {error}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default ProfileManagement;
