import moment from "moment";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { cancelBooking, getUserInfo, removeSplitUser } from "../../../Api/api";
import Popup from "../../booking/Popup";
import PopupInfo from "../../booking/PopupInfo";
import { Store } from "react-notifications-component";
import { AuthContext } from "../../AuthProvider";
import { CgSpinner } from "react-icons/cg";

const BookingDetailsModal = ({
    setOpenBookingModal,
    setOpenCancelModal,
    booking,
    setOpenConfirmCancel,
    setGetBookings,
    showTab
}: {
    setOpenBookingModal: Dispatch<SetStateAction<boolean>>;
    setOpenCancelModal: Dispatch<SetStateAction<boolean>>;
    booking: any;
    setOpenConfirmCancel: any;
    setGetBookings: any;
    showTab: string;
}) => {
    const { t } = useTranslation();
    const {
        clubName,
        court_info,
        clubProfile,
        start_datetime,
        stop_datetime,
        timezone,
        currency,
        total_price,
        amountPerPlayer,
        refundFee,
        activity_records_id,
        able_to_cancel,
        cancellation_time,
        date,
        time,
        timeEnd,
        isCreditPayment
    } = booking;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [cancelling, setCancelling] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [userId, setUserId] = useState("");
    const currentUser = useContext(AuthContext);

    const start = start_datetime,
        end = stop_datetime,
        startDate = moment.utc(start).tz(timezone)?.calendar({
            sameDay: "[Today] HH:mm",
            nextDay: "[Tomorrow] HH:mm",
            nextWeek: "ddd DD MMM, HH:mm",
            sameElse: "ddd DD MMM, HH:mm"
        });

    const courtType = court_info?.[0]?.indoor ? "indoor" : "outdoor";
    const isDouble = court_info?.[0]?.double ? "double" : "single";

    const sports =
        court_info && court_info.length && court_info[0]?.sport
            ? Object.entries(court_info[0].sport)
                  .filter(([key, value]) => value)
                  .map(([key]) => key)
            : [];

    const facilities =
        sports && sports.length ? [courtType, isDouble, ...sports] : [];

        const handleCancelBooking = async() => {
            setCancelling(true);
            if (booking?.player1?.email && booking?.player1?.email === userEmail){
                await cancelBooking(activity_records_id).then((cancelBookingResp: any) => {
                    console.log("loading => cancelBooking", cancelBookingResp);
                    if (cancelBookingResp) {
                        setOpenConfirmCancel(true);
                        setCancelling(false);
                        setGetBookings(true);
                        Store.addNotification({
                            title: t("bookingCancelled"),
                            message: t("bookingCancelSuccessful"),
                            type: "success",
                            container: "bottom-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000
                            }
                        });
                    } else {
                        setCancelling(false);
                        Store.addNotification({
                            title: t("somethingWrong"),
                            message: t("unableToCancelBooking"),
                            type: "danger",
                            container: "bottom-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000
                            }
                        });
                    }
                });
            }else{
                if(booking?.splitPaymentData && booking?.splitPaymentData?.transactionId) {
                const data = await removeSplitUser({
                  clubId: booking?.clubId,
                  playersId: [userId],
                  transactionId: booking?.splitPaymentData ? booking?.splitPaymentData.transactionId : '',
                });
        
                if (data?.success) {
                    setOpenConfirmCancel(true);
                    setCancelling(false);
                    setGetBookings(true);
                    Store.addNotification({
                        title: t("bookingCancelled"),
                        message: t("bookingCancelSuccessful"),
                        type: "success",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000
                        }
                    });
                }
                else {
                    setCancelling(false);
                }
              }
              else {
                setCancelling(false);
                Store.addNotification({
                    title: t("somethingWrong"),
                    message: t("unableToCancelBooking"),
                    type: "danger",
                    container: "bottom-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000
                    }
                });
            }
        }
    }; 

    useEffect(() => {
        const getUser = async () => {
            setLoading(true);
            try {
                const userData = await getUserInfo();
                setUserEmail(userData?.user?.email || "");
                setUserId(userData?.user?._id || "");
            } catch (error: any) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        getUser();
    }, [currentUser]);

    const checkNullAndUndefined = (value: any) => {
        if (value === undefined || value === null) {
          return true;
        }
        return false;
    };

    const convertToHours = () => {
    if (!checkNullAndUndefined(cancellation_time)) {
        try {
        const time = cancellation_time / (1000 * 60 * 60);
        const isInteger = Number.isInteger(time);
        return isInteger ? time : time.toFixed(1);
        } catch (error) {
        return 6;
        }
    }
    return 6;
    };

    const calculateDuration = (timeStart: string, timeEnd: string) => {
        // Split the time strings into hours and minutes
        const [startHour, startMinute] = timeStart.split(":").map(Number);
        const [endHour, endMinute] = timeEnd.split(":").map(Number);
        
        // Convert times to total minutes since midnight
        const startTotalMinutes = startHour * 60 + startMinute;
        const endTotalMinutes = endHour * 60 + endMinute;
        
        // Calculate the difference in minutes
        const duration = endTotalMinutes - startTotalMinutes;
    
        return duration;
    }

    return (
        <>
            <Popup
                onDelete={handleCancelBooking}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={booking?.player1?.email && booking?.player1?.email === userEmail ? `${t("rUSureCancelBooking")}` : `${t("rUSureLeaveBooking")}`}
                message={isCreditPayment === true || checkNullAndUndefined(refundFee) ? t("leaveBookingMessageForNotpaid") : t("leaveBookingMessageForOnlinepaid").replace("{amount}",`${refundFee} ${currency}`)}
            />
            <PopupInfo
                title={""}
                subTitle={"Cancelling booking..."}
                isOpen={cancelling}
                setIsOpen={setCancelling}
                isLoading={true}
            />
            <div className="bg-white w-full lg:min-w-[886px] rounded">
                <div className="bg-secondary text-white min-h-[100px] px-5 2xl:px-8 flex items-center justify-between gap-4 text-2xl xl:text-3xl font-bold">
                    <h4>{t("Profile.bookingDetails")}</h4>
                    <button
                        className="outline-none"
                        id="modal-close-btn"
                        onClick={() => {
                            setOpenBookingModal(false);
                        }}
                    >
                        <AiOutlineClose className="w-6 h-6 xl:w-7 xl:h-7" />
                    </button>
                </div>

                <div className="overflow-y-auto max-h-[calc(100vh-100px)] px-5 xl:px-8">
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 py-5 xl:py-8 items-center">
                        <div className="sm:flex sm:justify-between items-center h-full">
                            <div className="flex items-center gap-4 flex-wrap">
                                <img
                                    className="w-[95px] h-[95px] rounded-[8px] shadow shadow-gray-500"
                                    src={clubProfile || `/images/club-icon.png`}
                                    alt="Logo"
                                />
                                <div className="flex flex-col gap-2 text-secondary">
                                    <p className="text-[15px]">{clubName}</p>
                                    <h3 className="font-bold text-2xl">
                                        {court_info && court_info.length
                                            ? court_info[0]?.name
                                            : "-"}
                                    </h3>
                                    <div className="text-[15px] text-[#666B78]">
                                        <p>{facilities.join(" | ")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden sm:block ml-4 w-[1px] h-full bg-gray-300"></div>
                        </div>

                        <div className="flex flex-col gap-5 text-[18px]">
                            <div className="flex flex-wrap justify-between gap-3">
                                <p> {t("Profile.date")}</p>
                                <h4 className="font-semibold">{start_datetime && stop_datetime ? `${startDate}` : `${date}`}</h4>
                            </div>
                            <div className="flex flex-wrap justify-between gap-3">
                                <p> {t("Profile.time")}</p>
                                <div className="text-end">
                                    <h4 className="font-semibold">
                                        {start_datetime && stop_datetime ? `${moment
                                            .utc(stop_datetime)
                                            .tz(timezone)
                                            ?.diff(
                                                moment
                                                    .utc(start_datetime)
                                                    .tz(timezone),
                                                "minutes"
                                            )}` : `${calculateDuration(time, timeEnd)}`}{" "}
                                        min
                                    </h4>
                                    <p className="text-[15px]">
                                        {start_datetime && stop_datetime ? `${moment
                                            .utc(start)
                                            .tz(timezone)
                                            ?.format("HH:mm")}${" "}
                                        -${" "}
                                        ${moment
                                            .utc(end)
                                            .tz(timezone)
                                            ?.format("HH:mm")}` : `${time} - ${timeEnd}`}
                                    </p>
                                </div>
                            </div>
                            {/* <div className="flex flex-wrap justify-between gap-3">
              <p>Door code</p>
              <h4 className="font-semibold text-end">#A765Gh9</h4>
            </div> */}
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-between gap-3 text-[22px] border-y border-y-[#000b2926] py-5">
                        <h3> {t("Profile.totalPayment")}</h3>
                        <h3 className="font-semibold">
                            {booking?.activityType === "Book Court" ? total_price.toFixed(2) : amountPerPlayer.toFixed(2)}{" "}
                            {currency}
                        </h3>
                    </div>
                    {showTab === t("Profile.upcoming") && (booking?.activityType && booking?.activityType === "Book Court") && (!checkNullAndUndefined(able_to_cancel) && able_to_cancel) ? (
                        <div className="my-10 sm:mb-20 flex justify-center items-center max-w-[394px] mx-auto w-full">
                            {loading ? <CgSpinner className="animate-spin text-[64px]" /> : (<button
                                id="leaveBookingButton"
                                onClick={() => {
                                    // setOpenBookingModal(false);
                                    // setOpenCancelModal(true);
                                    setIsOpen(true);
                                }}
                                className="text-[19px] font-semibold sm:h-[64px] py-2 px-4 max-w-[394px] w-full rounded-3xl bg-secondary text-white relative md:-bottom-12"
                            >
                                {booking?.player1?.email === userEmail ? `${t("Profile.cancelBooking")}` : `${t("Profile.leaveBooking")}`}
                            </button>)}
                        </div>
                    ) : showTab === t("Profile.played") || (booking?.activityType !== "Book Court") ? null : 
                    (
                        <p id="canNotCancelBooking" className="text-red-500 text-center my-8">{t('uCanNotCancelPre') + ' ' + convertToHours() + ' ' +  t('uCanNotCancelPost')}</p>
                    )
                    }
                </div>
            </div>
        </>
    );
};

export default BookingDetailsModal;
