import React from "react";

interface AlertPopupProps {
    setIsOpen: any;
    isOpen: Boolean;
    message: string;
}

const AlertPopup: React.FC<AlertPopupProps> = ({
    isOpen,
    setIsOpen,
    message
}) => {

    const handleCancel = () => {
        setIsOpen(false);
    };

    return isOpen ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#00000080] z-[4000] px-2">
            <div className="bg-white p-5 rounded flex flex-col gap-5 items-center justify-center px-10">
                <p id="alertMessage" className="text-lg">{message}</p>
                <div className="flex items-center justify-center gap-5 flex-wrap">
                    <button
                        className="bg-secondary hover:bg-red-600 text-white rounded py-2 px-10 border-none transform duration-200"
                        onClick={handleCancel}
                    >
                        Okay
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default AlertPopup;
