import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { getClubActivities, getUserProfile } from "../../Api/api";
import MatchActivityCard from "./MatchActivityCard";
import TournamentActivityCard from "./TournamentActivityCard";
import ActivityTab from "./ActivityTab";
import DownloadAppPopup from "../DownloadAppPopup";
import { useBookingAtom } from "../../Utilities/clubStore";

interface ClubProfileActivitiesProps {
    clubId: string;
    setIsSlot?: Dispatch<SetStateAction<boolean>>;
    selectedActivity?: any,
    setSelectedActivity?: any
}

const ClubProfileActivities: React.FC<ClubProfileActivitiesProps> = ({
    clubId, setIsSlot,
    selectedActivity,
    setSelectedActivity
}) => {
    const [bookingAtom, setBookingAtom] = useBookingAtom();

    const { t } = useTranslation();

    const [allClubActivities, setAllClubActivities] = useState<any>([]);
    const [clubActivities, setClubActivities] = useState<any>([]);
    const [loading, setLoading] = useState<Boolean>(false);
    const [activityType, setActivityType] = useState(`${t("Profile.all")}`);
    const [isOpenAppDownloadModal, setIsOpenAppDownloadModal] = useState(false);
    const [userData, setUserData] = useState<{} | null>(null);

    function closeAppDownloadModal() {
        setIsOpenAppDownloadModal(false);
    }

    useEffect(() => {
        console.log("its effect");

        if (clubId) {
            console.log("clubId => clubId", clubId);

            setLoading(true);
            getClubActivities(clubId)
                .then((clubActivitiesResp) => {
                    // console.log("loading => clubActivitiesResp", clubActivitiesResp);
                    setLoading(false);
                    if (Array.isArray(clubActivitiesResp?.data)) {
                        setAllClubActivities(clubActivitiesResp?.data);
                        // setClubActivities(clubActivitiesResp?.data);
                        if (activityType === t("Profile.tournaments")) {
                            setClubActivities(
                                clubActivitiesResp?.data?.filter(
                                    (item: { activity: string }) =>
                                        item.activity === "Tournament"
                                )
                            );
                        } else if (activityType === t("Profile.coaching")) {
                            setClubActivities(
                                clubActivitiesResp?.data?.filter(
                                    (item: { activity: string }) => item.activity === "Training"
                                )
                            );
                        } else if (activityType === t("Profile.matches")) {
                            setClubActivities(
                                clubActivitiesResp?.data?.filter(
                                    (item: { activity: string }) => item.activity === "Match"
                                )
                            );
                        } else {
                            setClubActivities(clubActivitiesResp?.data);
                        }
                    }
                })
                // .then(() => {
                //     allClubActivities?.length === 0 && setLoading(false);
                // })
                .catch(() => {
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
                try {
                    getUserProfile().then((data)=>{
                        setUserData(data);
                    });
                } catch (error) {
                    
                }
        }
    }, [clubId, bookingAtom?.page]);

    useEffect(() => {
        if (selectedActivity && clubActivities) {
            const matchedActivity = clubActivities?.find(
                (clubActivity: any) => clubActivity?._id === selectedActivity
            );
            if (matchedActivity) {
                setBookingAtom({
                    ...bookingAtom,
                    clubActivity: matchedActivity,
                });
            }
        }
    }, [clubActivities]);

    console.log("loading => clubActivities", clubActivities);

    const filterActivity = (activity: string) => {
        if (activity === t("Profile.tournaments")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string }) =>
                        item.activity === "Tournament"
                )
            );
        } else if (activity === t("Profile.coaching")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string }) => item.activity === "Training"
                )
            );
        } else if (activity === t("Profile.matches")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string }) => item.activity === "Match"
                )
            );
        } else {
            setClubActivities(allClubActivities);
        }
    };

    return (
        <div className="bg-[#FBFBFB] px-2 py-5 sm:px-5 rounded-2xl">
            <div className="px-2 sm:px-0">
                <h2 className="text-2xl font-semibold text-left">
                    {t("Profile.activities")}
                </h2>
                {/* <p className="text-lg text-left mt-3">
                    {t("Profile.participateInManyActivities")}
                </p> */}
            </div>

            <ActivityTab
                activityType={activityType}
                setActivityType={setActivityType}
                allClubActivities={allClubActivities}
                setClubActivities={setClubActivities}
                onActivityClick={filterActivity}
            />
            {/* <div className="mt-6 bg-[#000b29] w-full bg-opacity-[0.15] h-[1px]" /> */}

            <div className="mt-6">
                {loading ? (
                    <div className="w-full h-[100px] flex justify-left">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-secondary"></div>
                        </div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-5 mt-3">
                        {clubActivities &&
                            clubActivities?.map(
                                (clubActivity: any, index: any) => (
                                    <div
                                        id={`clubActivity-${index}`}
                                        key={index}
                                        className="cursor-pointer h-full"
                                        onClick={() => {
                                            console.log(
                                                "clubActivity: " +
                                                    JSON.stringify(clubActivity)
                                            );
                                            if (
                                                clubActivity?.activity ===
                                                "Match"
                                            ) {
                                                setIsOpenAppDownloadModal(true);
                                            } else {
                                                setSelectedActivity(clubActivity?._id);
                                                setBookingAtom({
                                                    ...bookingAtom,
                                                    show: true,
                                                    page: 13,
                                                    clubActivity: clubActivity,
                                                    selectedCourt: clubActivity?.court_info?.[0],
                                                    price: clubActivity?.payment_per_person,
                                                    basePrice: clubActivity?.payment_per_person,
                                                    isTournamentType:
                                                        clubActivity?.tournament_type !==
                                                        "Group",
                                                        isTrainingType:
                                                        clubActivity?.tournament_type === "Group" ||
                                                        clubActivity?.tournament_type === "Course" ||
                                                        clubActivity?.tournament_type === "Personal" 
                                                });
                                            }
                                            setIsSlot && setIsSlot(false);
                                        }}
                                    >
                                        {clubActivity?.activity === "Match" ? (
                                            <MatchActivityCard
                                                clubActivity={clubActivity}
                                                userData={userData}
                                            />
                                        ) : (
                                            <TournamentActivityCard
                                                clubActivity={clubActivity}
                                            />
                                        )}
                                    </div>
                                )
                            )}
                    </div>
                )}
            </div>
            <div className="w-full h-[100px] flex justify-left mt-6">
                {clubActivities?.length === 0 && (
                    <p id="noActivitiesAvailable">{t("Activities.noActivities")}</p>
                )}
            </div>

            {/* <div className="flex items-center justify-center text-xl gap-5 mt-12 mb-8">
        <button className="flex items-center gap-2 text-gray-400 font-medium">
          <IoIosArrowBack className="w-5 h-5 text-gray-400" />
          <span>{t("previous")}</span>
        </button>
        <button className="flex items-center gap-2">
          <span className="underline font-medium">{t("next")}</span>
          <IoIosArrowForward className="w-5 h-5" />
        </button>
      </div> */}

            <DownloadAppPopup
                isOpenAppDownloadModal={isOpenAppDownloadModal}
                closeAppDownloadModal={closeAppDownloadModal}
            />
        </div>
    );
};

export default ClubProfileActivities;
