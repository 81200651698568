import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { useTranslation } from "react-i18next";
import DownloadAppPopup from "../DownloadAppPopup";
import SelectDate from "../clubProfile/SelectDate";
import moment from "moment-timezone";
import ActivityTab from "../clubProfile/ActivityTab";
import ComponentPagination from "../ComponentPagination";
// import ActivityModal from "./ActivityModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { AuthContext } from "../AuthProvider";
import { getClubActivities, getClubProfile, getClubProfileData } from "../../Api/api";
import BookingModal from "../booking/BookingModal";
import { useBookingAtom } from "../../Utilities/clubStore";
import ActivitiesTournamentCard from "./ActivitiesTournamentCard";
import ActivitiesMatchCard from "./ActivitiesMatchCard";

const ParticipateInActivities = ({
    allClubActivities,
    clubActivities,
    loading,
    setClubActivities,
    date,
    setDate,
    setAllClubActivities,
    setLoading
}: {
    allClubActivities: any;
    clubActivities: any;
    loading: boolean;
    setClubActivities: Dispatch<SetStateAction<any>>;
    date: any;
    setDate: Dispatch<SetStateAction<any>>;
    setAllClubActivities: Dispatch<SetStateAction<any>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
    const selectedClubID = localStorage.getItem('selectedClubID');
    // const [selectedDate, setSelectedDate] = useState<Moment>(moment());
    const { t } = useTranslation();
    const [activityType, setActivityType] = useState(`${t("Profile.all")}`);

    const currentUser = useContext<any>(AuthContext);
    const [clubData, setClubData] = useState<any>(null);

    const [price, setPrice] = useState<number>(0);
    const [basePrice, setBasePrice] = useState<number>(0);
    const [isShown, setIsShown] = useState<boolean>(false);
    const [mins, setMins] = useState<number>(0);
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [modalPage, setModalPage] = useState(1);
    const [selectedSlot, setSelectedSlot] = useState<string>(
        moment().format("HH:mm")
    );
    const [availableSlotState, setAvailableSlotState] = useState(null);
    const [selectedCourt, setSelectedCourt] = useState<any>(null);
    // const [userCredit, setUserCredit] = useState(0);
    const [selectedActivity, setSelectedActivity] = useState<any>(null);
    const [modalActive, setModalActive] = useState(false);

    const [bookingAtom, setBookingAtom] = useBookingAtom();

    const [isOpenAppDownloadModal, setIsOpenAppDownloadModal] = useState(false);

    const itemsPerPage = 10;
    const paginationRef = useRef<any>(null);

    const [currentPage, setCurrentPage] = useState(0);

    const [currentClubActivities, setCurrentClubActivities] = useState([]);

    const publishableKey = process.env.REACT_APP_STRIPE_KEY as string;

    const stripePromise = loadStripe(publishableKey);

    useEffect(() => {
        if (selectedActivity && clubActivities) {
            const matchedActivity = clubActivities?.find(
                (clubActivity: any) => clubActivity?._id === selectedActivity
            );
            if (matchedActivity) {
                setBookingAtom({
                    ...bookingAtom,
                    clubActivity: matchedActivity,
                });
            }
        }
    }, [clubActivities]);

    useEffect(() => {
        if (currentUser) {
            getCurrentCubProfile(selectedClubID, currentUser?.uid);
        } else {
            getCubProfileData(selectedClubID);
        }
    }, [currentUser, selectedClubID]);

    const getCurrentCubProfile = async (clubId: any, playerId: string) => {
        try {
            getClubProfile(clubId, playerId).then((resp: any) => {
                if (resp?.status === 200) {
                    if (!resp.data._id && resp.data.club_id) {
                        resp.data._id = resp.data.club_id;
                    }
                    setClubData(resp?.data);
                    setBookingAtom({
                        ...bookingAtom,
                        page: 13,
                        clubData: resp?.data
                    });
                }
            });
        } catch (error: any) {
            console.log("🚀 ~ file: ClubProfileMain.tsx:79 ~ getCubProfile ~ error:", error);
        }
    };

    const getCubProfileData = async (clubId: any) => {
        try {
            getClubProfileData(clubId).then((resp: any) => {
                if (resp?.status === 200) {
                    if (!resp.data._id && resp.data.club_id) {
                        resp.data._id = resp.data.club_id;
                    }
                    setClubData(resp.data);
                    setBookingAtom({
                        ...bookingAtom,
                        page: 1,
                        clubData: resp?.data
                    });
                }
            });
        } catch (error: any) {
            console.log("🚀 ~ file: ClubProfileMain.tsx:92 ~ getCubProfileData ~ error:", error);
        }
    };

    const openOrCloseModal = () => {
        setModalActive(!modalActive);
    };

    function closeAppDownloadModal() {
        setIsOpenAppDownloadModal(false);
    }

    const onDateClick = () => {
        if (activityType === t("Profile.tournaments")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { start_datetime: number; activity: string, timezone: string }) =>
                        moment.tz(item.start_datetime, item?.timezone).isSame(date, "day") &&
                        item.activity === "Tournament"
                )
            );
        } else if (activityType === t("Profile.coaching")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { start_datetime: number; activity: string, timezone: string }) =>
                        moment.tz(item.start_datetime, item?.timezone).isSame(date, "day") &&
                        item.activity === "Training"
                )
            );
        } else if (activityType === t("Profile.matches")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { start_datetime: number; activity: string, timezone: string }) =>
                        moment.tz(item.start_datetime, item?.timezone).isSame(date, "day") &&
                        item.activity === "Match"
                )
            );
        } else if (activityType === t("Profile.all")) {
            setClubActivities(
                allClubActivities?.filter((item: { start_datetime: number, timezone: string }) =>
                    moment.tz(item.start_datetime, item?.timezone).isSame(date, "day")
                )
            );
        } else {
            console.log("No matching activityType found"); // Debugging statement
        }
        setCurrentPage(0);
        paginationRef.current?.resetPagination();
    };

    useEffect(() => {
        if(allClubActivities !== null && allClubActivities?.length > 0) {
            setClubActivities(
                allClubActivities?.filter((item: { start_datetime: number, timezone: string }) =>
                    moment.tz(item.start_datetime, item?.timezone).isSame(date, "day")
                )
            );
        }
    }, [allClubActivities]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onDateClick();
    }, [date]);

    const filterActivity = (activity: string) => {
        if (activity === t("Profile.tournaments")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string; start_datetime: number, timezone: string }) =>
                        item.activity === "Tournament" &&
                        moment.tz(item.start_datetime, item?.timezone).isSame(date, "day")
                )
            );
            setCurrentPage(0);
        } else if (activity === t("Profile.coaching")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string; start_datetime: number, timezone: string }) =>
                        item.activity === "Training" &&
                        moment.tz(item.start_datetime, item?.timezone).isSame(date, "day")
                )
            );
            setCurrentPage(0);
        } else if (activity === t("Profile.matches")) {
            setClubActivities(
                allClubActivities?.filter(
                    (item: { activity: string; start_datetime: number, timezone: string }) =>
                        item.activity === "Match" &&
                        moment.tz(item.start_datetime, item?.timezone).isSame(date, "day")
                )
            );
            setCurrentPage(0);
        } else {
            setClubActivities(
                allClubActivities?.filter((item: { start_datetime: number, timezone: string }) =>
                    moment.tz(item.start_datetime, item?.timezone).isSame(date, "day")
                )
            );
            setCurrentPage(0);
        }
    };

    // useEffect(()=>{
    //     const getClubActivitiesData = async () => {
    //         try{
    //             const clubActivitiesResp = await getClubActivities(selectedClubID || "");
    //             // localStorage.setItem('selectedClubID', selectedClubID);
    //             setAllClubActivities(clubActivitiesResp?.data);
    //             setClubActivities(clubActivitiesResp?.data);
    //             setLoading(false);

    //             if (clubActivitiesResp?.data?.length === 0) {
    //                 setLoading(false);
    //             }

    //             if (activityType === t("Profile.tournaments")) {
    //                 setClubActivities(
    //                     clubActivitiesResp?.data?.filter(
    //                         (item: { start_datetime: number; activity: string, timezone: string }) =>
    //                             moment.tz(item.start_datetime, item?.timezone).isSame(date, "day") &&
    //                             item.activity === "Tournament"
    //                     )
    //                 );
    //             } else if (activityType === t("Profile.coaching")) {
    //                 setClubActivities(
    //                     clubActivitiesResp?.data?.filter(
    //                         (item: { start_datetime: number; activity: string, timezone: string }) =>
    //                             moment.tz(item.start_datetime, item?.timezone).isSame(date, "day") &&
    //                             item.activity === "Training"
    //                     )
    //                 );
    //             } else if (activityType === t("Profile.matches")) {
    //                 setClubActivities(
    //                     clubActivitiesResp?.data?.filter(
    //                         (item: { start_datetime: number; activity: string, timezone: string }) =>
    //                             moment.tz(item.start_datetime, item?.timezone).isSame(date, "day") &&
    //                             item.activity === "Match"
    //                     )
    //                 );
    //             } else if (activityType === t("Profile.all")) {
    //                 setClubActivities(
    //                     clubActivitiesResp?.data?.filter((item: { start_datetime: number, timezone: string }) =>
    //                         moment.tz(item.start_datetime, item?.timezone).isSame(date, "day")
    //                     )
    //                 );
    //             } else {
    //                 console.log("No matching activityType found"); // Debugging statement
    //             }
    //         }
    //         catch (error) {
    //             console.log("🚀 ~ getClubActivitiesData ~ error:", error)
    //             setLoading(false);
    //         }
    //     }
    //     if(bookingAtom?.page === 131 ){
    //         getClubActivitiesData();
    //         // onDateClick();
    //     }
    // },[bookingAtom?.page])

    useEffect(() => {
        if (clubActivities?.length) {
            const currentCards = currentPage * itemsPerPage,
                sliceUpto = currentCards + itemsPerPage;
            setCurrentClubActivities(
                clubActivities.slice(currentCards, sliceUpto)
            );
        } else {
            setCurrentClubActivities([]);
        }
    }, [currentPage, clubActivities]);

    const onPageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="pt-9 px-4 md:px-16">
            <h2 id="clubActivitiesHeading" className="text-3xl text-center">
                {t("Activities.subTitle")}
            </h2>
            <div className="flex justify-center">
            <ActivityTab
                activityType={activityType}
                setActivityType={setActivityType}
                allClubActivities={allClubActivities}
                setClubActivities={setClubActivities}
                onActivityClick={filterActivity}
            />
            </div>
            <SelectDate
                selectedDate={date}
                setSelectedDate={setDate}
                dayCount={8}
                onDateClick={onDateClick}
            />

            {loading ? (
                <div className="w-full h-[100px] flex justify-center">
                    <div className="flex justify-center items-center">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-secondary"></div>
                    </div>
                </div>
            ) : (
                <div className="mt-[60px] max-w-[1000px] mx-auto">
                    {currentClubActivities?.length > 0 &&
                        currentClubActivities?.map(
                            (clubActivity: any, index: any) => (
                                <div
                                    id={`activityCard-${index}`}
                                    key={index}
                                    className="my-6 bg-[#FAFAFA] cursor-pointer"
                                    onClick={() =>
                                        {
                                            if(clubActivity?.activity === "Match"){
                                                setIsOpenAppDownloadModal(true);
                                            }
                                            else{
                                                setSelectedActivity(clubActivity?._id);
                                                setBookingAtom({
                                                    ...bookingAtom,
                                                    show: true,
                                                    page: 13,
                                                    clubActivity: clubActivity,
                                                    price: clubActivity?.payment_per_person,
                                                    isTournamentType:
                                                        clubActivity?.tournament_type !==
                                                        "Group",
                                                        isTrainingType:
                                                        clubActivity?.tournament_type === "Group" ||
                                                        clubActivity?.tournament_type === "Course" ||
                                                        clubActivity?.tournament_type === "Personal" 
                                                });
                                            }
                                        }
                                    }
                                >
                                    {clubActivity?.activity === "Match" ? (
                                        <ActivitiesMatchCard
                                            clubName={clubData?.name}
                                            clubActivity={clubActivity}
                                            page="activities"
                                        />
                                    ) : (
                                        <ActivitiesTournamentCard
                                            clubName={clubData?.name}
                                            clubActivity={clubActivity}
                                            page="activities"
                                        />
                                    )}
                                </div>
                            )
                        )}
                </div>
            )}
            <div className="w-full h-[100px] flex justify-center">
                {clubActivities?.length === 0 && (
                    <p id="noActivities">{t("Activities.noActivities")}</p>
                )}
            </div>

            <div className="mb-10">
                <ComponentPagination
                    totalItems={clubActivities?.length}
                    itemsPerPage={itemsPerPage}
                    onPageChange={onPageChange}
                    ref={paginationRef}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            <DownloadAppPopup
                isOpenAppDownloadModal={isOpenAppDownloadModal}
                closeAppDownloadModal={closeAppDownloadModal}
            />
            <Elements stripe={stripePromise}>
                <BookingModal
                    price={price}
                    setPrice={setPrice}
                    mins={mins}
                    setMins={setMins}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    page={modalPage}
                    pageSetter={setModalPage}
                    show={modalActive}
                    onClose={openOrCloseModal}
                    currency={clubData?.currency}
                    selectedCourt={selectedCourt}
                    availableSlotState={availableSlotState}
                    selectedSlot={selectedSlot}
                    basePrice={basePrice}
                    setBasePrice={setBasePrice}
                    bookingData={clubData}
                    setIsShown={setIsShown}
                    isSlot={false}
                />
            </Elements>
        </div>
    );
};

export default ParticipateInActivities;
