import React, { useEffect, useState } from "react";
import { ComponentBookingCard } from "../Components/booking/ComponentBookingCard";
import { ComponentDiscover } from "../Components/booking/ComponentDiscover";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { getSearchNearbyClubs } from "../Api/api";
import BookingModal from "../Components/booking/BookingModal";
import { useSearchParams } from "react-router-dom";
import { CgSpinner } from "react-icons/cg";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const publishableKey = process.env.REACT_APP_STRIPE_KEY as string;

const stripePromise = loadStripe(publishableKey);

const getClubsFromLocalStorage = () => {
    let clubs = localStorage.getItem("clubs");
    if (clubs) {
        return JSON.parse(clubs);
    } else return [];
};

export type CourtType = "indoor_courts" | "outdoor_courts";
export type CourtDimension = "single_courts" | "double_courts";
export type SportType =
    | "padel"
    | "tennis"
    | "badminton"
    | "squash"
    | "pickleball";

const BookingPage: React.FC<{}> = ({}) => {
    const [currentUser, setCurrentUser] = useState<any>(null);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
        });
    }, []);

    const [data, setData] = useState(getClubsFromLocalStorage());
    const [loadingData, setLoadingData] = useState(false);

    const [modalActive, setModalActive] = useState(false);
    const [modalPage, setModalPage] = useState(1);
    const [queryParams] = useSearchParams();
    const [sports, setSports] = useState<SportType[]>([
        "padel",
        "tennis",
        "squash",
        "badminton",
        "pickleball"
    ]);
    const [radius, setRadius] = useState<number>(50);
    const [courtDimension, setCourtDimension] = useState<CourtDimension[]>([
        "single_courts"
    ]);

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState<string>(
        moment().format("HH:mm")
    );

    const searchParams = Object.fromEntries(queryParams);

    console.log("loading => searchParams", searchParams);

    const openOrCloseModal = () => {
        setModalActive(!modalActive);
    };

    const searchNearByClubs = async (
        clubName: string,
        sport: string,
        date: any
    ) => {
        const encodedClubName = encodeURIComponent(clubName); // Encode the clubName
        setLoadingData(true);
        setData([]);
        const selectedSport =
            sport === "all" || sport === undefined || sport === ""
                ? []
                : [sport];
        setSelectedStartDate(date);
        let data = {
            radius,
            latitude: 78.9012,
            longitude: 12.3456,
            sports,
            courtDimension,
            clubName: encodedClubName,
            selectedSport,
            date,
            currentUser
        };

        const defaultResult = await getSearchNearbyClubs(data);
        if (
            defaultResult?.data?.searched_clubs &&
            defaultResult?.data?.searched_clubs?.length > 0
        ) {
            setData(
                defaultResult?.data?.searched_clubs?.filter(
                    (club: any) => 
                    (sport === null || sport === undefined || sport === "" || sport === "all" || club?.sports_available?.[sport] === true)
                )
            );
            console.log(
                "loading => defaultResult.data.searched_clubs",
                defaultResult.data.searched_clubs
            );
        } else {
            setData(getClubsFromLocalStorage());
        }
        setLoadingData(false);
    };

    useEffect(() => {
        setSelectedStartDate(null);
        const date = queryParams.get("date") || new Date();
        localStorage.setItem(
            "selectedDate",
            JSON.stringify(moment(date).format("YYYY-MM-DD"))
        );

        searchNearByClubs(
            queryParams.get("location") ?? "",
            queryParams.get("sport") ?? "",
            moment(date)?.format("YYYY-MM-DD") ?? ""
        );
    }, [currentUser]);

    const [price, setPrice] = useState<number>(0);
    const [basePrice, setBasePrice] = useState<number>(0);
    const [isShown, setIsShown] = useState<boolean>(false);
    const [mins, setMins] = useState<number>(0);
    const [endTime, setEndTime] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [selectedCourt, setSelectedCourt] = useState<any>(null);
    const [availableSlotState, setAvailableSlotState] = useState(null);
    const [selectedBookingData, setSelectedBookingData] = useState({
        currency: "SEK"
    });

    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("Title.bookSlot")} | Padel Mates</title>
            </Helmet>
            <div className="mx-auto">
                <ComponentDiscover
                    isHome={false}
                    searchClubs={searchNearByClubs}
                />
                <hr className="mx-auto w-full" />

                <Elements stripe={stripePromise}>
                    <div className="flex flex-col items-center justify-center">
                        {loadingData && (
                            <div className="flex flex-col gap-4 items-center justify-center">
                                <p className="text-xl">
                                    {t("searchingForLocations")}...
                                </p>
                                <CgSpinner className="animate-spin text-[64px]" />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col divide-y mx-auto w-full max-w-[1150px]">
                        {data.length > 0
                            ? data.map((court: any) => (
                                  <div key={court._id}>
                                      <ComponentBookingCard
                                          setPrice={setPrice}
                                          setMins={setMins}
                                          pageSetter={setModalPage}
                                          jsonData={court}
                                          selectedStartDate={selectedStartDate}
                                          setSelectedBookingData={
                                              setSelectedBookingData
                                          }
                                          bookingData={selectedBookingData}
                                          endTime={endTime}
                                          setEndTime={setEndTime}
                                          startTime={startTime}
                                          setStartTime={setStartTime}
                                          onClose={openOrCloseModal}
                                          selectedSlot={selectedSlot}
                                          setSelectedSlot={setSelectedSlot}
                                          setSelectedCourt={setSelectedCourt}
                                          setAvailableSlotState={
                                              setAvailableSlotState
                                          }
                                          basePrice={basePrice}
                                          setBasePrice={setBasePrice}
                                          currentUser={currentUser}
                                      />
                                  </div>
                              ))
                            : !loadingData ? <div><p className="text-center py-10 text-lg">{t("noClubsAvailable")}</p></div> : null}
                    </div>
                    <BookingModal
                        setPrice={setPrice}
                        startTime={startTime}
                        setIsShown={setIsShown}
                        setMins={setMins}
                        mins={mins}
                        setEndTime={setEndTime}
                        endTime={endTime}
                        setStartTime={setStartTime}
                        bookingData={selectedBookingData}
                        page={modalPage}
                        pageSetter={setModalPage}
                        show={modalActive}
                        onClose={openOrCloseModal}
                        price={price}
                        currency={selectedBookingData?.currency}
                        selectedCourt={selectedCourt}
                        availableSlotState={availableSlotState}
                        selectedSlot={selectedSlot}
                        basePrice={basePrice}
                        setBasePrice={setBasePrice}
                    />
                </Elements>
            </div>
        </>
    );
};

export default BookingPage;
