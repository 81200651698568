import React from "react";
import { useTranslation } from "react-i18next";

type CreditTypes = {
    credit: number;
};

const ProfileCredits = ({ credit }: CreditTypes) => {
    const { t } = useTranslation();

    const formatCredit = (credit: number) => {
        const creditStr = credit.toString();
        return creditStr.length > 10 ? `${creditStr.slice(0, 10)}...` : creditStr;
    };

    return (
        <div className="flex flex-col w-full">
            <h2 className="font-semibold text-xl text-left mb-3">
                {t("Profile.myCredits")}
            </h2>
            {/* <hr className="mt-4 mb-6" /> */}
            {credit > 0 ? (
                <div className="text-left">
                    <p className="text-[15px]">
                        {t("Profile.yourCurrentCredit")}
                    </p>
                    <h2 className={`${credit.toString().length > 9 ? "2xl:text-[40px] xl:text-[40px]" : "2xl:text-[50px] xl:text-[45px]"} mt-4 text-3xl font-bold break-words leading-none`}>
                        {formatCredit(credit)}
                    </h2>
                </div>
            ) : (
                <div className="flex flex-col gap-2 text-[15px] text-left">
                    <p>{t("Profile.youDontHaveAnyCreditYet")}</p>
                    {/* <p>{t("Profile.contactClub")}</p> */}
                </div>
            )}
        </div>
    );
};

export default ProfileCredits;
