import React from "react";
import { useTranslation } from "react-i18next";
import { GrFormCheckmark, GrLinkPrevious } from "react-icons/gr";
import { Store } from "react-notifications-component";

const OnboardingGender = ({
    setCurrentStep,
    selectedGender,
    setSelectedGender,
    handleOnboardUser
}: {
    setCurrentStep: any;
    setSelectedGender: any;
    selectedGender: string;
    handleOnboardUser: any;
}) => {
    const genders = [
        { id: 1, name: "Male" },
        { id: 2, name: "Female" },
        { id: 3, name: "Other" }
    ];
    const { t } = useTranslation();
    return (
        <div className="text-center p-10 w-[90%] md:w-[40%] bg-[#FBFBFB] rounded-3xl shadow">
            <h3 className="mb-10 font-bold text-xl text-secondary text-center">
                {t("gender")}
            </h3>

            <ul className="grid w-full gap-6 md:grid-cols-3">
                {genders.map((gender: { id: number; name: string }) => (
                    <div className="flex flex-col" key={gender.id}>
                        <li onClick={() => setSelectedGender(gender.name)}>
                            <label
                                className={
                                    selectedGender === gender.name
                                        ? "flex items-center justify-center w-full p-2 text-secondary bg-primary rounded-full cursor-pointer"
                                        : "flex items-center justify-center w-full p-2 text-secondary bg-white border border-secondary rounded-full cursor-pointer"
                                }
                            >
                                <div className="w-full text-lg">
                                    {gender.name}
                                </div>
                            </label>
                        </li>
                    </div>
                ))}
            </ul>
            <div className="mt-20 flex justify-between items-center">
                <button
                    className="font-semibold outline-none rounded-full bg-white text-secondary border border-secondary w-auto px-10 py-3"
                    onClick={() => {
                        setCurrentStep((prev: number) => prev - 1);
                    }}
                >
                    <GrLinkPrevious />
                </button>
                <button
                    className="font-semibold outline-none rounded-full bg-white text-secondary border border-secondary w-auto px-10 py-3"
                    onClick={() => {
                        if (selectedGender) {
                            handleOnboardUser();
                        } else {
                            Store.addNotification({
                                title: "Failed",
                                message: "Please select a gender",
                                type: "danger",
                                container: "center",
                                animationIn: [
                                    "animate__animated",
                                    "animate__fadeIn"
                                ],
                                animationOut: [
                                    "animate__animated",
                                    "animate__fadeOut"
                                ],
                                dismiss: {
                                    duration: 3000
                                }
                            });
                        }
                    }}
                >
                    <GrFormCheckmark />
                </button>
            </div>
        </div>
    );
};

export default OnboardingGender;
