import { t } from "i18next";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { applyCoupon } from "../Api/api";
const Coupon = ({
    clubId,
    voucher,
    setVoucher,
    startTime,
    endTime,
    sport,
    activityType,
    firebaseUID
}: {
    voucher: any;
    clubId: string;
    setVoucher: any;
    startTime?:any;
    endTime?:any;
    sport?:any;
    activityType?:any
    firebaseUID?:any
}) => {
    const [voucherCode, setVoucherCode] = useState<string>("");
    const [applyingVoucher, setApplyingVoucher] = useState<boolean>(false);
    const [voucherSuccessMessage, setVoucherSuccessMessage] = useState<
        string | undefined | null
    >(localStorage.getItem("voucherSuccessMsg"));
    const [voucherErrorMessage, setVoucherErrorMessage] = useState<
        string | undefined | null
    >("");

    const checkActivity = (activity: string) => {
        console.log('activityactivity',activity)
        switch (activity.toLowerCase()) { 
            case 'training':
                return 'trainings';
            case 'tournament':
                return 'tournaments';
            default:
                return activity;
        }
    };

    const handleApplyVoucher = () => {
        if (voucherCode && voucherCode.length) {
            setVoucherErrorMessage("");
            setVoucherSuccessMessage("");
            setApplyingVoucher(true);
            applyCoupon(
                clubId,
                voucherCode,
                setApplyingVoucher,
                setVoucherErrorMessage,
                startTime,
                endTime,
                sport,
                checkActivity(activityType),
                firebaseUID
            ).then((voucherResp: any) => {
                if (voucherResp?.data?.status) {
                    setApplyingVoucher(false);
                    const message = voucherResp?.data.max_discount_amount
                        ? `${t("youWillGet")} ${voucherResp?.data.amount_in_percentage}% ${t("discount")} ${t("upTo")} ${voucherResp?.data.max_discount_amount}`
                        : `${t("youWillGet")} ${voucherResp?.data.amount_in_percentage}% ${t("discount")}`;
                    setVoucherSuccessMessage(message);
                    localStorage.setItem("voucherSuccessMsg", message);
                    setVoucher(voucherResp?.data);
                } else {
                    setApplyingVoucher(false);
                    setVoucherErrorMessage(voucherResp?.data?.rejection_reason || "failed to apply coupon");
                    setTimeout(() => {
                        setVoucherErrorMessage("");
                    }, 3000);
                }
            });
        }
    };
    return (
        <>
            <div className="w-full flex mt-8 mb-16 h-10 justify-center items-center">
                <div className="relative w-full h-full">
                    <input
                        id="couponInput"
                        type="text"
                        className="h-14 md:h-16 w-full pl-3 text-md md:text-lg bg-gray-50 pr-10 rounded-[15px] z-0 focus:shadow focus:outline-primary"
                        placeholder={t("enterPromoCode")}
                        value={voucherCode}
                        onChange={(e: any) => {
                            setVoucherCode(e.target.value);
                        }}
                        autoFocus
                    />
                    <div className="absolute top-2 right-3">
                        <button
                            id="couponApplybtn"
                            className="flex justify-around items-center h-10 md:h-12 px-2 w-[100px] md:w-[120px] text-white rounded-full bg-secondary hover:bg-primary hover:text-secondary transform duration-200 text-base font-semibold"
                            onClick={() => handleApplyVoucher()}
                            disabled={applyingVoucher || voucher}
                        >
                            {applyingVoucher ? (
                                <CgSpinner className="animate-spin text-[24px]" />
                            ) : (
                                t("PromoComponent.apply")
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {voucherSuccessMessage ? (
                <div
                    id="voucherSuccessMessage"
                    className="mb-3 hidden w-full items-center rounded-lg bg-green-100 px-6 py-5 text-base text-green-600 data-[te-alert-show]:inline-flex"
                    role="alert"
                    data-te-alert-init
                    data-te-alert-show
                >
                    <span className="mr-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                fillRule="evenodd"
                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                    {voucherSuccessMessage}
                </div>
            ) : voucherErrorMessage ? (
                <div
                    id="voucherErrorMessage"
                    className="mb-3 hidden w-full items-center rounded-lg bg-red-100 px-6 py-5 text-base text-red-600 data-[te-alert-show]:inline-flex"
                    role="alert"
                    data-te-alert-init
                    data-te-alert-show
                >
                    <span className="mr-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                fillRule="evenodd"
                                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                    {voucherErrorMessage}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Coupon;
