import React, { useContext, useEffect, useState } from "react";
import ProfileHero from "./ProfileHero";
import FavoriteCenter from "./FavoriteCenter";
import CentreMembership from "./CentreMembership";
import Bookings from "./Bookings";
import { getUserProfile } from "../../Api/api";
import { AuthContext } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
import { getLevelInfo } from "../../Utilities/levels";
import { Modal } from "../ui/Modal";

const ProfileMain = () => {
    const currentUser = useContext(AuthContext);
    const [userProfile, setUserProfile] = useState(null);
    const [levelInfo, setLevelInfo] = React.useState({});
    const [sportType, setSportType] = useState<string>("padel");
    const [enableInfoModal, setEnableInfoModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser) {
            getUserProfile(sportType).then((profileResp: any) => {
                console.log("loading  => profileResp", profileResp);
                if (profileResp) {
                    setUserProfile(profileResp);
                    setLevelInfo({
                        ...getLevelInfo(sportType, profileResp?.level?.im ?? 1)
                    });
                } else {
                    navigate("/onboarding");
                }
            });
        }
    }, [currentUser, sportType]);

    return (
        <>
            <div className="bg-white text-secondary pb-20">
                <ProfileHero
                    userProfile={userProfile}
                    levelInfo={levelInfo}
                    setSportType={setSportType}
                    sportType={sportType}
                    setEnableInfoModal={setEnableInfoModal}
                    enableInfoModal={enableInfoModal}
                />

                <div className="px-4 md:px-16">
                    <div className="mt-10 mx-auto flex flex-col md:flex-row gap-10">
                        <div className="flex-col gap-10 w-full md:w-[calc(34%-20px)] md:max-w-[393px] md:ml-auto hidden">
                            {/* <FavoriteCenter /> */}
                            <div id="widget:bookingModal"></div>
                            <script
                                defer
                                src="../../../dist/widget_bookingModal_bundle.js"
                            ></script>
                            {/* <CentreMembership hasMembership={true} /> */}
                        </div>

                        <div className="flex flex-col gap-10 w-full md:max-w-[979px] md:mx-auto">
                            <Bookings />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Modal open={enableInfoModal} setOpen={setEnableInfoModal}>
                <div className="bg-white p-5 lg:py-8 lg:px-16 font-semibold rounded-2xl w-full mx-auto">
                    <h3 className="text-center text-2xl mb-10 capitalize">
                        {t("whatLevel")} {"currentSport"}?
                    </h3>

                    <div className="flex justify-center mt-10">
                        <button
                            onClick={() => {
                                setEnableInfoModal(!enableInfoModal);
                            }}
                            className="bg-secondary text-white hover:text-secondary hover:bg-white hover:border hover:border-secondary rounded-full px-10 h-[49px] text-[19px]"
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Modal> */}
        </>
    );
};

export default ProfileMain;
