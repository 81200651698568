import moment from "moment-timezone";
import React from "react";
import dummyProfileNew from "../../widgets/bookingWidget/bookingModal/images/dummyProfileNew.png";
import { t } from "i18next";

const TournamentActivityCard = ({
    clubActivity,
    page
}: {
    clubActivity: any;
    page?: string;
}) => {
    return (
        <div>
            <div className="relative rounded-[10px] shadow border border-[#000b29] border-opacity-[0.15] px-2 pt-5 pb-7 sm:px-4 w-full h-full">
                <div className="flex gap-3 flex-wrap items-end justify-between ">
                    <div className="flex flex-col gap-2 justify-between items-stretch h-full w-full">
                        <div className="flex flex-col gap-1 w-full">
                            <p id="activityTypeTitle" className="text-xs flex items-center gap-2 font-medium uppercase">
                                <img src="/images/trophy.svg" alt="trophy" />
                                {clubActivity?.activity === "Training" ? `${t("Profile.coaching")}` : clubActivity?.activity}{" "}
                                {clubActivity?.tournament_type}
                            </p>
                            <p id="timeText" className="text-sm font-medium">
                                {clubActivity.start_datetime
                                    ? moment.tz(
                                          clubActivity.start_datetime , clubActivity?.timezone
                                      ).format("ddd")
                                    : ""}{" "}
                                {clubActivity.start_datetime
                                    ? moment.tz(
                                          clubActivity.start_datetime , clubActivity?.timezone
                                      ).format("MM/DD/YYYY")
                                    : ""}{" "}
                                |{" "}{clubActivity.start_datetime
                                    ? moment.tz(
                                          clubActivity.start_datetime , clubActivity?.timezone
                                      ).format("HH:mm")
                                    : ""}{" "}
                                -{" "}
                                {clubActivity.start_datetime
                                    ? moment.tz(clubActivity.stop_datetime , clubActivity?.timezone).format(
                                          "HH:mm"
                                      )
                                    : ""}
                            </p>
                            <h4 className="text-xl font-semibold">
                                {clubActivity?.title}
                            </h4>
                            <p className="text-sm flex items-center gap-2 font-medium">
                                <img
                                    src="/images/location.svg"
                                    alt="location"
                                />
                                {clubActivity?.address} {clubActivity?.city}{" "}
                                {clubActivity?.country}
                            </p>
                            <p className="text-sm flex items-center gap-2 font-medium">
                                Court: {clubActivity?.court_info?.[0]?.name}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-between items-center gap-3 flex-wrap w-full font-medium">
                        <div className="flex gap-2 flex-wrap items-center">
                            <img
                                src="/images/badge.svg"
                                alt="badge"
                                className={`${
                                    page === "activities" ? "md:w-8 w-6" : "w-6"
                                }`}
                            />
                            <p
                                className={`${
                                    page === "activities"
                                        ? "text-lg"
                                        : "text-sm"
                                } font-medium`}
                            >
                                {clubActivity?.min_level}-
                                {clubActivity?.max_level}
                            </p>

                            <div className="ml-2 flex">
                            {clubActivity?.tournament_type === "team_americano" ? <>{clubActivity?.players?.slice(0, 4)?.map((group: any[], groupIndex: number) => {
                                const secondPlayer = group?.[1]; // Access the second object in the sub-array
                                return (<img
                                        key={groupIndex}
                                        src={secondPlayer?.profilePic||dummyProfileNew}
                                        alt="Player avatar"
                                        className="max-w-6 min-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400"
                                    />)
                            })}</> : <>{clubActivity?.players?.slice(0, 4)?.map(
                                (
                                    player: {
                                        profilePic: string | undefined;
                                    },
                                    index: React.Key | null | undefined
                                ) => (
                                    <img src={player?.profilePic||dummyProfileNew} alt="Player avatar" className="max-w-6 min-w-6 h-6 -ml-2 text-gray-400 bg-white rounded-full border-2 border-gray-400" /> )
                            )}</>}
                            </div>
                        </div>
                        <div
                            className={`text-white ${
                                page === "activities"
                                    ? "md:text-lg  text-[13px]"
                                    : "text-[13px]"
                            } h-[31px] flex gap-[1px] items-center cursor-pointer`}
                        >
                            <button
                                className={`${
                                    page === "activities"
                                        ? "md:text-lg  text-[13px] rounded-l-[12px] h-[33px] px-3"
                                        : "text-[13px] rounded-l-full h-[31px] px-2.5"
                                } bg-secondary font-medium`}
                            >
                                {clubActivity?.current_no_of_players}/
                                {clubActivity?.no_of_players}
                            </button>
                            <button
                                id="priceCard"
                                className={`${
                                    page === "activities"
                                        ? "md:text-lg  text-[13px] rounded-r-[12px] h-[33px] px-3"
                                        : "text-[13px] rounded-r-full h-[31px] px-2.5"
                                } bg-secondary font-semibold`}
                            >
                                {clubActivity?.payment_per_person !== 0
                                    ? clubActivity?.payment_per_person?.toFixed(
                                          0
                                      )
                                    : 0}{" "}
                                {clubActivity?.currency
                                    ? clubActivity?.currency
                                    : "kr"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TournamentActivityCard;
