import React, {
    useState,
    FormEvent,
    useRef,
    useContext,
    useEffect
} from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Combobox } from "@headlessui/react";
import { AuthContext } from "../AuthProvider";
import { getSearchNearbyClubs } from "../../Api/api";

interface ISearchParams {
    location?: string | undefined;
    sport?: string | undefined;
}
interface ComponentSearchCentresBarProps {
    searchCentre: (
        sport?: ISearchParams["sport"],
        location?: ISearchParams["location"]
    ) => void;
}

const ComponentSearchCentresBar: React.FC<ComponentSearchCentresBarProps> = ({
    searchCentre
}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParams = Object.fromEntries(searchParams);

    const inputRef = useRef<HTMLInputElement>(null);
    const [showClubs, setShowClubs] = useState(false);
    const [clubs, setClubs] = useState([]);
    const currentUser = useContext(AuthContext);

    const [sport, setSport] = useState(queryParams.sport?.toLowerCase() ?? ""); // State for sport selection
    const [location, setLocation] = useState(queryParams.location ?? ""); // State for location input

    const getClubs = () => {
        if (!sport && !location) {
            return;
        }
        const formData: ISearchParams = {};
        if (sport) {
            formData.sport = sport;
        }
        if (location) {
            formData.location = location.trim();
        }
        const urlSearchParam = new URLSearchParams(
            formData as Record<string, string>
        );
        setSearchParams(urlSearchParam);
        searchCentre(formData.sport, formData.location);
    };

    const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getClubs();
    };

    const searchNearByClubs = async (clubName: string) => {
        const encodedClubName = encodeURIComponent(clubName); // Encode the clubName
        setClubs([]);
        let data = {
            // radius,
            latitude: 78.9012,
            longitude: 12.3456,
            clubName: encodedClubName,
            currentUser
        };

        const defaultResult = await getSearchNearbyClubs(data);
        if (
            defaultResult?.data?.searched_clubs &&
            defaultResult.data.searched_clubs.length > 0
        ) {
            setClubs(defaultResult.data.searched_clubs);
        }
    };

    const handelClubClick = (club: any) => {
        setLocation(club.name);
        setClubs([]);
        getClubs();
        // let formData = {
        //     sport,
        //     club: club.name
        // };
        // getClubs(formData);
    };
    useEffect(() => {
        const handleClickOutside = (event: { target: any }) => {
            if (inputRef.current && !inputRef.current?.contains(event.target)) {
                setShowClubs(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    return (
        <div>
            <div className="sm:w-[85%] mx-auto bg-neutral-50 w-full py-8 lg:py-14 flex flex-col justify-center items-center  text-center text-secondary">
                <h2 className="lg:text-[45px] text-[30px] font-semibold">
                    {t("FindCentres.title")}.
                </h2>
                <div>
                    <form
                        className="lg:flex lg:flex-row lg:justify-between lg:items-center flex flex-col items-center flex-wrap  px-8 pt-6 pb-3"
                        onSubmit={handleSearchSubmit}
                    >
                        {/* <div className="relative p-2 flex-2">
                            <label className="absolute bg-white text-[#000b2999] top-[-3px] left-[22px] text-[14px] px-1">
                                {t("FindCentres.form.lable3")}
                            </label>
                            <input
                                className="lg:min-w-[350px] w-[250px] h-[60px] border-[1px] border-[#000b2999] rounded-[50px] placeholder-[#000b2999] text-[#000b2999] p-4"
                                type="text"
                                placeholder={`${t(
                                    "FindCentres.form.label3_placeholder"
                                )}....`}
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                        </div> */}

                        <Combobox>
                            {({ open }) => (
                                <>
                                    <div className="relative p-2 flex-2">
                                        <Combobox.Label className="absolute bg-[#FBFBFB] text-[#000b2999] top-[-3px] left-[22px] text-[14px] px-1">
                                            {t("Discover.form.lable3")}
                                        </Combobox.Label>
                                        <Combobox.Input
                                            ref={inputRef}
                                            className="lg:min-w-[350px] w-[250px] h-[60px] border-[1px] border-[#000b2999] rounded-[50px] placeholder-[#000b2999] text-[#000b2999] p-4"
                                            placeholder={t(
                                                "Discover.form.placeholder"
                                            )}
                                            value={location}
                                            onChange={(event) => {
                                                setShowClubs(true);
                                                const inputValue =
                                                    event.target.value;
                                                setLocation(inputValue);
                                                if (
                                                    inputValue &&
                                                    inputValue.length
                                                ) {
                                                    searchNearByClubs(
                                                        inputValue
                                                    );
                                                }
                                            }}
                                            onKeyUp={(e: any) => {
                                                if (e.key === "Enter") {
                                                    const tempClub = clubs.find(
                                                        (club: any) =>
                                                            club.name ===
                                                            e.target.value
                                                    );

                                                    if (tempClub) {
                                                        handelClubClick(
                                                            tempClub
                                                        );
                                                    }
                                                }
                                            }}
                                        />

                                        {showClubs && clubs.length > 0 && (
                                            <Combobox.Options
                                                static
                                                className="absolute z-10 w-full bg-white shadow-md text-secondary h-max max-h-[300px] overflow-auto mt-2"
                                            >
                                                {clubs.map(
                                                    (
                                                        club: any,
                                                        index: number
                                                    ) => (
                                                        <Combobox.Option
                                                            key={index}
                                                            value={club.name}
                                                            onClick={() => {
                                                                handelClubClick(
                                                                    club
                                                                );
                                                            }}
                                                        >
                                                            {({ active }) => (
                                                                <div
                                                                    className={`cursor-pointer px-3 py-2 text-left w-full min-h-[30px] h-max text-sm ${
                                                                        active
                                                                            ? "bg-secondary text-white"
                                                                            : "hover:bg-secondary hover:text-white"
                                                                    }`}
                                                                >
                                                                    {club.name}
                                                                </div>
                                                            )}
                                                        </Combobox.Option>
                                                    )
                                                )}
                                            </Combobox.Options>
                                        )}
                                    </div>
                                </>
                            )}
                        </Combobox>

                        <div className="relative p-2 flex=1">
                            <div className="min-w-[250px] h-[60px] bg-white border-[1px] border-[#000b2999] rounded-[50px] text-[#000b2999] p-4">
                                <label className="absolute bg-white text-[#000b2999] top-[-3px] left-5 text-[14px] px-1">
                                    {t("FindCentres.form.lable1")}
                                </label>
                                <select
                                    className="w-full outline-none bg-white appearance-none"
                                    value={sport}
                                    onChange={(e) => {
                                        setSport(e.target.value.trim());
                                    }}
                                    style={{
                                        background:
                                            "url(/images/down-arrow.png) no-repeat 92% 50%"
                                    }}
                                >
                                    <option value="all">
                                        {t("FindCentres.form.Select.opt-1")}
                                    </option>
                                    <option value="tennis">
                                        {t("FindCentres.form.Select.opt-2")}
                                    </option>
                                    <option value="padel">
                                        {t("FindCentres.form.Select.opt-3")}
                                    </option>
                                    <option value="badminton">
                                        {t("FindCentres.form.Select.opt-4")}
                                    </option>
                                    <option value="pickleball">
                                        {t("FindCentres.form.Select.opt-5")}
                                    </option>
                                    <option value="squash">
                                        {t("FindCentres.form.Select.opt-6")}
                                    </option>
                                    <option value="golf">
                                        {t("FindCentres.form.Select.opt-7")}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="
            transition-all
            bg-secondary
            hover:shadow-lg
            m-2
            cursor-pointer text-white
            lg:w-[170px] w-[250px] h-[60px] flex justify-center p-4 lg:p-6
            items-center rounded-[50px]

            "
                        >
                            <span className="font-semibold inline-flex justify-between items-center gap-8">
                                {t("FindCentres.form.Submit")}
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth={0}
                                    viewBox="0 0 16 16"
                                    className="submit-icon group-hover:text-secondary"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                        </button>
                    </form>
                </div>
                <hr />
            </div>
        </div>
    );
};
export default ComponentSearchCentresBar;
